import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { PartnerRequirementDTO } from "../partner-requirement-DTO";
import { useAlert } from "../../../../../contexts/alert-context";
import { addNew, getById } from "../partner-requirement-service";
import CountryInputAutocomplete from "../../../../../components/CountryInputAutocomplete/CountryInputAutocomplete";
import { CountryDTO } from "../../../../../components/CountryInputAutocomplete/models/country-DTO";

interface ModalProps {
  onClose: () => void;
  requirementId: string;
}

const PartnerRequirementForm: React.FC<ModalProps> = ({
  onClose,
  requirementId,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [partnerRequirementDTO, setPartnerRequirementDTO] =
    useState<PartnerRequirementDTO | null>(null);

  const [isCheckedUpload, setIsCheckedUpload] = useState(
    partnerRequirementDTO?.partnerRequirementInfoDTO?.documentUploadRequired ??
      false
  );
  const [isCheckedApproval, setIsCheckedApproval] = useState(
    partnerRequirementDTO?.partnerRequirementInfoDTO
      ?.documentApprovalRequired ?? false
  );
  const [isCheckedExpiration, setIsCheckedExpiration] = useState(
    partnerRequirementDTO?.partnerRequirementInfoDTO?.hasExpiration ?? false
  );
  const [valueDaysExpiration, setValueDaysExpiration] = useState<number>(0);
  const [valueDaysWarning, setValueDaysWarning] = useState<number>(0);
  const [unitDaysExpiration, setUnitDaysExpiration] = useState(1);
  const [unitDaysWarning, setUnitDaysWarning] = useState(1);
  const renderOptions = [
    { display: `${t("days")}`, value: 1 },
    { display: `${t("weeks")}`, value: 7 },
    { display: `${t("months")}`, value: 30 },
    { display: `${t("years")}`, value: 365 },
  ];

  const handleCheckboxChangeUpload = () => {
    setIsCheckedUpload(!isCheckedUpload);
    setValue(
      "partnerRequirementInfoDTO.documentUploadRequired",
      !isCheckedUpload,
      {
        shouldValidate: true,
      }
    );
  };

  useEffect(() => {
    setValue(
      "partnerRequirementInfoDTO.expirationPeriod",
      valueDaysExpiration * unitDaysExpiration,
      {
        shouldValidate: true,
      }
    );

    setValue(
      "partnerRequirementInfoDTO.expirationWarning",
      valueDaysWarning * unitDaysWarning,
      {
        shouldValidate: true,
      }
    );
  }, [
    valueDaysExpiration,
    unitDaysExpiration,
    valueDaysWarning,
    unitDaysWarning,
  ]);

  const handleCheckboxChangeApproval = () => {
    setIsCheckedApproval(!isCheckedApproval);
    setValue(
      "partnerRequirementInfoDTO.documentApprovalRequired",
      !isCheckedApproval,
      {
        shouldValidate: true,
      }
    );
  };

  const handleCheckboxChangeExpiration = () => {
    if (!isCheckedExpiration == false) {
    }
    setIsCheckedExpiration(!isCheckedExpiration);
    setValue("partnerRequirementInfoDTO.hasExpiration", !isCheckedExpiration, {
      shouldValidate: true,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PartnerRequirementDTO>();

  useEffect(() => {
    if (requirementId) {
      getById(requirementId).then((data) => {
        if (data) {
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof PartnerRequirementDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof PartnerRequirementDTO, value as any);
            }
          });
          setIsCheckedApproval(
            data.partnerRequirementInfoDTO.documentApprovalRequired
          );
          setIsCheckedUpload(
            data.partnerRequirementInfoDTO.documentUploadRequired
          );
          setValue("partnerRequirementInfoDTO.country", data.partnerRequirementInfoDTO.country[0]);
          setIsCheckedExpiration(data.partnerRequirementInfoDTO.hasExpiration);
          setValueDaysWarning(data.partnerRequirementInfoDTO.expirationWarning);
          setValueDaysExpiration(
            data.partnerRequirementInfoDTO.expirationPeriod
          );
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (document: PartnerRequirementDTO) => {
    try {
      if (document.partnerRequirementInfoDTO.hasExpiration) {
        if (
          document.partnerRequirementInfoDTO.expirationPeriod == null ||
          document.partnerRequirementInfoDTO.expirationPeriod == 0
        ) {
          return setAlert({
            text: `${t("alert-error-date-empty")}`,
            type: "error",
          });
        }
      }
      if (id) {
        await addNew(document, id);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-date-empty")}:`, type: "error" });
    }
  };
  const [selectedCountryDetails, setSelectedCountryDetails] =
    useState<CountryDTO | null>(null);

  const handleCountryChange = (country: CountryDTO) => {
    setSelectedCountryDetails(country);

    setValue("partnerRequirementInfoDTO.country", country.country, {
      shouldValidate: true,
    });
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-partners-requirements")}</h2>
          <div className="flex-column">
            <CountryInputAutocomplete
              label={t("label-country")}
              placeholder={t("placeholder.country")}
              register={register}
              errors={errors}
              onChange={handleCountryChange}
              initialValue={
                partnerRequirementDTO?.partnerRequirementInfoDTO?.country
              }
            />
            {!selectedCountryDetails && (
              <span>{t("error-input-required")}</span>
            )}
          </div>
          <div className="flex-column">
            <label htmlFor="legalName">{t("label-requirement")}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="legalName"
              {...register("partnerRequirementInfoDTO.requirement", {
                required: true,
              })}
            />
            {errors.partnerRequirementInfoDTO?.requirement && (
              <span>{t("error-input-required")}</span>
            )}
          </div>
        </div>
        <br />

        <div className="expiration-checkbox-wrapper">
          <input
            type="checkbox"
            checked={isCheckedUpload}
            onChange={handleCheckboxChangeUpload}
          />
          <p>{t("label-document-upload-required")}</p>
        </div>

        <div className="expiration-checkbox-wrapper">
          <input
            type="checkbox"
            checked={isCheckedApproval}
            onChange={handleCheckboxChangeApproval}
          />
          <p>{t("label-document-approval-demanded")}</p>
        </div>

        <div className="expiration-main-context">
          <div className="expiration-checkbox-wrapper">
            <input
              type="checkbox"
              checked={isCheckedExpiration}
              onChange={handleCheckboxChangeExpiration}
            />
            <p>{t("expiration.expiration-checkbox-title")}</p>
          </div>

          {isCheckedExpiration && (
            <div>
              <div className="expiration-dynamic-render">
                <div className="expiration-flex-column">
                  <p style={{ marginBottom: 12 }}>
                    {t("label-document-expiring-period")}
                  </p>
                  <div className="expiration-wrapper">
                    <div className="left-wrapper-content">
                      <button
                        type="button"
                        onClick={() =>
                          setValueDaysExpiration(
                            valueDaysExpiration <= 1
                              ? 0
                              : valueDaysExpiration - 1
                          )
                        }
                        className="number-input-button"
                      >
                        -
                      </button>
                      <input
                        className="expiration-input-qtt-days"
                        value={valueDaysExpiration}
                        onChange={(e) =>
                          setValueDaysExpiration(parseInt(e.target.value))
                        }
                        type="number"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setValueDaysExpiration(valueDaysExpiration + 1)
                        }
                        className="number-input-button"
                      >
                        +
                      </button>
                    </div>
                    <select
                      onChange={(e) =>
                        setUnitDaysExpiration(parseInt(e.target.value))
                      }
                      style={{ width: "35%" }}
                    >
                      {renderOptions.map((obj, index) => (
                        <option key={index} value={obj.value}>
                          {obj.display}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="expiration-flex-column">
                <p>{t("expiration.previous-warning")}</p>
                <div className="expiration-wrapper">
                  <div className="left-wrapper-content">
                    <button
                      type="button"
                      onClick={() =>
                        setValueDaysWarning(
                          valueDaysWarning <= 1 ? 0 : valueDaysWarning - 1
                        )
                      }
                      className="number-input-button"
                    >
                      -
                    </button>
                    <input
                      className="expiration-input-qtt-days"
                      value={valueDaysWarning}
                      onChange={(e) =>
                        setValueDaysWarning(parseInt(e.target.value))
                      }
                      type="number"
                    />
                    <button
                      type="button"
                      onClick={() => setValueDaysWarning(valueDaysWarning + 1)}
                      className="number-input-button"
                    >
                      +
                    </button>
                  </div>
                  <select
                    onChange={(e) =>
                      setUnitDaysWarning(parseInt(e.target.value))
                    }
                    style={{ width: "35%" }}
                  >
                    {renderOptions.map((obj, index) => (
                      <option key={index} value={obj.value}>
                        {obj.display}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="form-fields flex-column">
          <div className="flex-column">
            <label htmlFor="observations" style={{ paddingBottom: 10 }}>
              {t("label-general-observations")}
            </label>
            <textarea
              placeholder={t("placeholder.observations")}
              id="observations"
              {...register("observations.observations")}
            />
          </div>
        </div>

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartnerRequirementForm;

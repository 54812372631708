import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import { COLLECTIONS } from "../../../assets/enums/firebase-colections";
import { ScrapsFormGeneratorDTO } from "./scraps-form-generator-DTO";
import { ScrapRegisterForm } from "./scraps-form-generator-DTO";

// add new scrapGenerator ScrapsFormGeneratorDTO
export async function addNewScrapGeneratorForm(data: ScrapRegisterForm) {
    await addDoc(
        collection(firestore, COLLECTIONS.SCRAPS_GENERATOR_FORM), data
    )
}
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { ChangelogDTO } from "./models/changelog-DTO";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";

export async function getChangelog(): Promise<ChangelogDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.CHANGELOG);
  const q = query(
    collectionRef,
    orderBy("date", "desc")
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ChangelogDTO[];
  return items;
}

export async function getChangelogById(id: string): Promise<ChangelogDTO> {
  const docRef = doc(firestore, COLLECTIONS.CHANGELOG, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ChangelogDTO;
}

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const CardFeedback: any = ({}) => {
  const { t } = useTranslation();

  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    {
      title: "feedback.feedback-one",
      subtitle: "feedback.caption-one",
    },
    {
      title: "feedback.feedback-two",
      subtitle: "feedback.caption-two",
    },
    {
      title: "feedback.feedback-three",
      subtitle: "feedback.caption-three",
    },
    {
      title: "feedback.feedback-four",
      subtitle: "feedback.caption-four",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="title-description">
      <h3 className="detals-text">{t("landing-page.title-feedback")} </h3>
      <div className="card-feedback">
        <span className="text-feedback">"{t(texts[textIndex].title)}"</span>
        <span className="text-feedback-detals">
          {t(texts[textIndex].subtitle)}
        </span>
      </div>
    </div>
  );
};

export default CardFeedback;

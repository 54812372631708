import React, { useState } from "react";
import ModalLoginWithoutPassword from "../modal-login-without-password/modal-login-without-password";
import { useTranslation } from "react-i18next";

const ButtonLoginWithoutPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div>
      <button
        className="login-without-password"
        type="button"
        onClick={handleOpenModal}
      >
        {t("label-login-without-password")}
      </button>
      {isModalOpen && <ModalLoginWithoutPassword onClose={handleCloseModal} />}
    </div>
  );
};

export default ButtonLoginWithoutPassword;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    dto: any;
    expirationDate: string;
    warningPeriod: string;
    hasExpirationDate: string;
    expirationDateInit?: string;
    warningPeriodInit?: number;
    hasExpirationDateInit?: boolean;
    dataHandler: (date: string, value: any) => void;
}

export default function Expiration({
    expirationDate,
    warningPeriod,
    hasExpirationDate,
    expirationDateInit,
    warningPeriodInit,
    hasExpirationDateInit,
    dto,
    dataHandler,
}: Props) {
    const { t } = useTranslation();
    const renderOptions = [
        { display: `${t("days")}`, value: 1 },
        { display: `${t("weeks")}`, value: 7 },
        { display: `${t("months")}`, value: 30 },
        { display: `${t("years")}`, value: 365 },
    ];
    const [isChecked, setIsChecked] = useState<boolean>(hasExpirationDateInit || false);
    const [unit, setUnit] = useState(1);
    const [value, setValue] = useState<number>(warningPeriodInit || 0);
    const [expiration, setExpiration] = useState(expirationDateInit || "")

    const handleCheckboxChange = () => {
        if (!isChecked == false) {
            dataHandler(expirationDate, "");
            dataHandler(warningPeriod, "");
            setValue(0);
            setUnit(1);
        }
        setIsChecked(!isChecked);
        dataHandler(hasExpirationDate, !isChecked);
    };

    useEffect(() => {
        if (hasExpirationDate && isChecked) {
            dataHandler(warningPeriod, value * unit);
        }
    }, [unit, value]);

    return (
        <div className="expiration-main-context">
            <div className="expiration-checkbox-wrapper">
                <input
                    type="checkbox"
                    checked={isChecked}
                    id="expiration-checkbox"
                    onChange={handleCheckboxChange}
                />
                <label htmlFor="expiration-checkbox">{t('expiration.expiration-checkbox-title')}</label>
            </div>

            {isChecked && (
                <div>
                    <div className="expiration-dynamic-render">
                        <div className="expiration-flex-column">
                            <p style={{ marginBottom: 12 }}>{t('expiration.validate-date-input')}</p>
                            <input
                                value={expiration}
                                type="date"
                                onChange={(e) => {
                                    setExpiration(e.target.value)
                                    dataHandler(expirationDate, e.target.value)}
                                }
                            />
                        </div>
                    </div>

                    <div className="expiration-flex-column">
                        <p>{t('expiration.previous-warning')}</p>
                        <div className="expiration-wrapper">
                            <div className="left-wrapper-content">
                                <button
                                    type="button"
                                    onClick={() => setValue(value <= 1 ? 0 : value - 1)}
                                    className="number-input-button"
                                >
                                    -
                                </button>
                                <input
                                    className="expiration-input-qtt-days"
                                    value={value}
                                    onChange={(e) => setValue(parseInt(e.target.value))}
                                    type="number"
                                />
                                <button
                                    type="button"
                                    onClick={() => setValue(value + 1)}
                                    className="number-input-button"
                                >
                                    +
                                </button>
                            </div>
                            <select
                                onChange={(e) => setUnit(parseInt(e.target.value))}
                                style={{ width: "35%" }}
                            >
                                {renderOptions.map((obj, index) => (
                                    <option key={index} value={obj.value}>
                                        {obj.display}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

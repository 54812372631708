import { t } from "i18next";
import folderReloadPath from "./../../assets/img/folder-reload.png"

function NoData() {
    return (
        <div style={{width:"100%"}} className="flex-column align-center justify-center">
            <img style={{marginBottom: "2rem", maxHeight: "15rem"}} src={folderReloadPath} alt="no data ilustration" />
            <h2 style={{color: "var(--extra-grey-medium)"}}>{t("no-data.info-label")}</h2>
        </div>
    )
}

export default NoData;
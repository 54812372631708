import { useTranslation } from "react-i18next";
import icon from "../../../../assets/img/icon.svg";

const ClickRepresentative: any = ({}) => {
  const { t } = useTranslation();

  const subject = t("mail.subject");
  const body = t("mail.body");

  return (
    <div className="container-representative-click">
      <div className="container-representative marginleft ">
        <div>
          <a className="title-click" href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
            {t("landing-page.click")}
          </a>
        </div>
        <div className="icon">
          <img
            width={25}
            height={25}
            src={icon}
            alt="ilustration - open email"
          />
        </div>
      </div>
      <div className="title-representative font-bold">
        <span className="font-weight">
        <a className="title-click" href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
        {t("landing-page.speak-representative")}
          </a>
          
        </span>
      </div>
    </div>
  );
};

export default ClickRepresentative;

import React, { useEffect, useState } from "react";
import "./EcommerceCard.css";

export interface EcommerceCardProps {
    imageUrl: string | string[];
    price: string;
    title?: string;
    description?: string;
    companyName?: string;
    buttonAction: () => void;
    unitPrice: string;
    buttontext: string;
    children?: React.ReactNode;
    customBottom?: React.ReactNode;
    onClick: () => void;
}

const EcommerceCard: React.FC<EcommerceCardProps> = ({
    imageUrl,
    price,
    title,
    description,
    companyName,
    buttonAction,
    buttontext,
    unitPrice,
    children,
    customBottom,
    onClick,
}) => {
    const [actualImage, setActualImage] = useState<string>();
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        if (typeof imageUrl !== "string") {
            setActualImage(imageUrl[0]);

            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % imageUrl.length;
                    setActualImage(imageUrl[newIndex]);
                    return newIndex;
                });
            }, 5000);

            return () => clearInterval(interval);
        } else {
            setActualImage(imageUrl);
        }
    }, [imageUrl]);

    return (
        <div className="ecommerce-card" onClick={onClick}>
            <div className="card-image">
                {actualImage && <img width="310px" src={actualImage} alt={title} />}
                <div className="image-footer">
                    <div className="price">{price}/{unitPrice}</div>
                </div>
            </div>
            <div className="card-content">
                {children ? (
                    children
                ) : (
                    <>
                        <h3 className="title">{title}</h3>
                        <p className="description">{description}</p>
                        <p className="company-name">{companyName}</p>
                    </>
                )}
            </div>
            <div className="card-footer" style={{ justifyContent: customBottom ? "start" : "flex-end" }}>
                {customBottom ? (
                    customBottom
                ) : (
                    <button className="card-button" onClick={buttonAction}>
                        {buttontext}
                    </button>
                )}
            </div>
        </div>
    );
};

export default EcommerceCard;

import {
  DocumentData,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { auth, firestore, functions, storage } from "../utils/firebase-config";
import { COLLECTIONS } from "../assets/enums/firebase-colections";
import { UserInfoDTO } from "../pages/settings/components/users/models/users-DTO";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";

export async function saveNewAsync(collectionFirebase: string, data: any) {
  const docRef = await addDoc(collection(firestore, collectionFirebase), data);
  saveLog("add", collectionFirebase, data, docRef.id);
  return docRef;
}

export async function saveNewWithId(
  collectionFirebase: string,
  data: any,
  id: string
) {
  setDoc(doc(firestore, collectionFirebase, id), data);
  saveLog("add", collectionFirebase, data, id);
}

export async function saveNew(collectionFirebase: string, data: any) {
  const docRef = addDoc(collection(firestore, collectionFirebase), data);
  saveLog("add", collectionFirebase, data, (await docRef).id);
}

export async function updateAsync(
  collectionFirebase: string,
  data: any,
  id: string
) {
  const docRef = await setDoc(doc(firestore, collectionFirebase, id), data, {
    merge: true,
  });
  saveLog("update", collectionFirebase, data, id);
  return docRef;
}

export function update(collectionFirebase: string, data: any, id: string) {
  setDoc(doc(firestore, collectionFirebase, id), data, {merge: true});
  saveLog("update", collectionFirebase, data, id);
}
var docReff: DocumentData | null;

export async function getUser() {
  const user = auth.currentUser;
  if (user) {
    const userRef = doc(firestore, COLLECTIONS.USERS, user.uid);
    const userDoc = await getDoc(userRef);
    docReff = userDoc;
    return userDoc;
  } else {
    return null;
  }
}

export function callFunction(
  functionName: string,
  data?: any,
  id?: string
): Promise<HttpsCallableResult<unknown>> {
  return new Promise((resolve, reject) => {
    const updateName = httpsCallable(functions, functionName);
    saveLog("function", functionName, data ?? {}, id ?? "");
    if (data != null) {
      updateName(data)
        .then((result) => {
          resolve(result as HttpsCallableResult<unknown>);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      updateName()
        .then((result) => {
          resolve(result as HttpsCallableResult<unknown>);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

export async function sumTotal(
  collectionFirebase: string,
  id: string,
  numToSum: number
) {
  const docRef = doc(firestore, collectionFirebase, id);
  const document = await getDoc(docRef);
  if (document.exists()) {
    const numberToUpdate = document.data()["total"];
    const newNumber = numberToUpdate + numToSum;
    update(collectionFirebase, { total: newNumber }, id);
  } else {
    saveNewWithId(collectionFirebase, { total: numToSum }, id);
  }
}

async function saveLog(
  type: string,
  collectionFirebase: string,
  data: any,
  id: string
) {
  if (docReff == null) {
    docReff = await getUser();
  }

  if (docReff) {
    var user = docReff.data() as UserInfoDTO;

    const map1 = {
      type: type,
      collection: collectionFirebase,
      dataString: JSON.stringify(data),
      dataMap: data,
      date: new Date(),
      id: id,
    };

    var map = { ...map1, ...user };

    addDoc(collection(firestore, COLLECTIONS.CHANGELOG), map);
  }
}

export async function saveFiles(
  collectionFirebase: string,
  id: string,
  folderName: string,
  files: File[]
) {
  const urls: string[] = [];

  for (const image of files) {
    const dateString: string = new Date().toISOString();
    const storageRef = ref(storage, `${folderName}/${dateString}---${image.name}`);
    await uploadBytes(storageRef, image);
    const url = await getDownloadURL(storageRef);
    urls.push(url);
  }
  update(collectionFirebase, { files: arrayUnion(...urls) }, id);
}

export async function deleteFile(
  collectionFirebase: string,
  id: string,
  urlFile: string
) {
 update(collectionFirebase, { files: arrayRemove(urlFile) }, id);
  
  const storageRef = ref(storage, urlFile);  
  deleteObject(storageRef);
}

export function saveToLocalStorage (key: string, value: any) {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error("Error saving to localStorage", error);
  }
};

export function getFromLocalStorage(key: string) {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return undefined;
    }
    return JSON.parse(serializedValue);
  } catch (error) {
    console.error("Error getting data from localStorage", error);
    return undefined;
  }
};
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../../contexts/alert-context";
import { useTranslation } from "react-i18next";
import { AddressInfoDTO, ScrapDTO } from "./models/scrap-DTO";
import { useForm } from "react-hook-form";
import ScrapCategoryInputAutocomplete from "../../../../../components/ScrapCategoryInputAutocomplete/ScrapCategoryInputAutocomplete";
import { useState, useEffect } from "react";
import { ScrapsCategoryInfoDTO } from "../../../scraps-DTO";
import { unityENUM } from "../../../../../assets/enums/unit-enum";
import { periodENUM } from "../../../../../assets/enums/period-enum";
import {
  ContainersTypeDTO,
  getContainersByCountryName,
} from "../../../../../services/containers-service";
import {
  CountryConfigDTO,
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../../services/address-service";
import genericImage from "./../../../../../assets/svgs/material-list.svg";
import { CurrencyInput } from "react-currency-mask";
import CollapsibleSection from "../../../../../components/ColapseSection/CollapsibleSection";
import Expiration from "../../../../../components/Expiration/Expiration";
import { ExpirationDTO } from "../../../../../components/Expiration/models/ExpirationDTO";
import AddressSectionFields from "../../../../../components/AddressSectionFields/AddressSectionFields";
import { StatusEnum } from "../../../../../assets/enums/status-enum";
import { MaterialBidTypeEnum } from "../../../../../assets/enums/bid-type-enum";
import ScrapDealerInputAutocomplete from "../../../../../components/ScrapDealersIInputAutocomplete/ScrapDealersIInputAutocomplete";
import {
  addNewScrapAdmin,
  fetchFulfilledRequirements,
  fetchPartnerRequirementsTotalByCountry,
  getScrapAdminById,
} from "../../scrap-admin-service";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../../../utils/firebase-config";
import uploadIcon from "../../../../../assets/svgs/upload.svg";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { DealersInvitedTableDTO } from "./models/dealers-invited-DTO";
import UploadCsvModal from "../../../../../components/ImportExportCsv/UploadCsvModal/UploadCsvModal";
import { useAuth } from "../../../../../contexts/auth-context";
import ScrapDealersInvitedModalForm from "./components/scrap-dealers-invited-modal";
import { applyDecimalMask } from "../../../../../utils/formatting";
import {
  getScrapGeneratorById,
  getScrapGeneratorInfo,
} from "../../../../scrap-generators/scrap-generator-service";
import {
  ScrapGeneratorDTO,
  ScrapGeneratorInfoDTO,
} from "../../../../scrap-generators/scrap-generators-DTO";

const ScrapForm: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { id, scrapId } = useParams<{ id: string; scrapId: string }>();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const data = { originId: scrapId };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    watch,
  } = useForm<ScrapDTO>();

  const [containers, setContainers] = useState<ContainersTypeDTO[]>([]);
  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();
  const [selectedCategoryDetails, setSelectedCategoryDetails] =
    useState<ScrapsCategoryInfoDTO | null>(null);
  const [scrapAdmin, setScrapAdmin] = useState<ScrapDTO | null>(null);
  const [dealersInvited, setDealersInvited] = useState<any[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedContainerType, setSelectedContainerType] =
    useState<string>("");
  const [contractValue, setContractValue] = useState<string>("");
  const [targetPrice, setTargetPrice] = useState<string>("");
  const [bidValue, setBidValue] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState("TO_DEAL");
  const [showContract, setShowContract] = useState(false);
  const [selectedUnity, setSelectedUnity] = useState("");
  const [generator, setGenerator] = useState<ScrapGeneratorInfoDTO | null>(
    null
  );
  const [tableData, setTableData] = useState<DealersInvitedTableDTO[]>([]);
  const tableHeader = [
    `${t("label-invited")}`,
    `${t("label-fulfiled-requirements")}`,
    `${t("label-invitation")}`,
    `${t("label-proposed-value")}`,
    `${t("label-attachment")}`,
    `${t("label-status")}`,
  ];
  const [editTableModalOpen, setEditTableModalOpen] = useState(false);
  const [editTableItem, setEditTableItem] =
    useState<DealersInvitedTableDTO | null>();

  const handleOpenTableModal = (rowItem: DealersInvitedTableDTO) => {
    setEditTableItem(rowItem);
    setEditTableModalOpen(true);
  };

  const handleCloseTableModal = async (
    updatedData?: DealersInvitedTableDTO
  ) => {
    if (updatedData) {
      await setTableData((prevDealers) =>
        prevDealers.map((dealer) =>
          dealer.id === updatedData.id ? updatedData : dealer
        )
      );
    }
    setEditTableItem(null);
    setEditTableModalOpen(false);
  };

  useEffect(() => {
    if (tableData) {
      setValue("dealersInvitedTable.dealersInvitedList", tableData);
    }
  }, [tableData]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as File[];
    setSelectedImages(files);

    const previewsArray = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            resolve(reader.result);
          }
        };
      });
    });

    Promise.all(previewsArray).then((previews) => {
      setPreviews(previews);
    });
  };

  const handleStatusChange = (event: any) => {
    const { value } = event.target;
    setSelectedStatus(value);
    setValue("scrapInfo.status", value);
  };

  const handleUnityChange = (event: any) => {
    const { value } = event.target;
    setSelectedUnity(t(`unityENUM.${value}`));
    setValue("scrapInfo.unity", value);
  };

  useEffect(() => {
    if (scrapId) {
      getScrapAdminById(scrapId).then((data) => {
        if (data) {
          if (data.scrapInfo) {
            setValue("scrapInfo.status", data.scrapInfo.status || "");
            setValue(
              "scrapInfo.scrapCategory",
              data.scrapInfo.scrapCategory || ""
            );
            handleCategoryChange(data.scrapInfo.scrapCategory);
            setValue(
              "scrapInfo.specialDescription",
              data.scrapInfo.specialDescription || ""
            );
            setValue("scrapInfo.quantity", data.scrapInfo.quantity || "");
            setQuantityMaskedValue(data.scrapInfo.quantity || "");
            setValue("scrapInfo.unity", data.scrapInfo.unity || "");
            setSelectedUnity(t(`unityENUM.${data.scrapInfo.unity}`));
            setValue("scrapInfo.period", data.scrapInfo.period || "");
            setValue(
              "scrapInfo.pickUpFrequency",
              data.scrapInfo.pickUpFrequency || ""
            );
            setValue(
              "scrapInfo.responsibleShipping",
              data.scrapInfo.responsibleShipping || ""
            );
            setValue(
              "scrapInfo.containerNeeded",
              data.scrapInfo.containerNeeded
            );
            setContainerNeeded(data.scrapInfo.containerNeeded);
            setValue(
              "scrapInfo.containerType",
              data.scrapInfo.containerType || ""
            );
            setValue(
              "scrapInfo.containerSize",
              data.scrapInfo.containerSize || ""
            );
            setValue(
              "scrapInfo.containerQuantity",
              data.scrapInfo.containerQuantity || ""
            );
            setContainerQuantityMaskedValue(
              data.scrapInfo.containerQuantity || ""
            );
            setValue(
              "scrapInfo.operationAddress",
              data.scrapInfo.operationAddress || ""
            );
            setAddress(data.scrapInfo.operationAddress);
            setSelectedContainerType(data.scrapInfo.containerType);
            setValue("scrapInfo.containerType", data.scrapInfo.containerType);
            setPreviews(data.scrapInfo.images);

            if (
              data.scrapInfo.status !== "TO_DEAL" &&
              data.scrapInfo.status !== "AWAITING_BID" &&
              data.scrapInfo.status !== "IN_BID"
            ) {
              setShowContract(true);
            } else {
              setShowContract(false);
            }
          }

          if (data.dealersInvitedTable) {
            setTableData(data.dealersInvitedTable.dealersInvitedList);
            setValue("dealersInvitedTable.dealersInvitedList", tableData);
          }

          if (data.scrapNegotiation) {
            setValue(
              "scrapNegotiation.targetPrice",
              data.scrapNegotiation.targetPrice || ""
            );
            setValue(
              "scrapNegotiation.salesDate",
              data.scrapNegotiation.salesDate || ""
            );
            setValue(
              "scrapNegotiation.pickUpFrequency",
              data.scrapNegotiation.pickUpFrequency || ""
            );
            setValue(
              "scrapNegotiation.bidNegotiationType",
              data.scrapNegotiation.bidNegotiationType
            );
          }

          if (data.scrapBid) {
            setValue("scrapBid.bidType", data.scrapBid.bidType || "");
            setValue("scrapBid.bidDate", data.scrapBid.bidDate || "");
            setValue("scrapBid.bidValue", data.scrapBid.bidValue || "");
            setValue(
              "scrapBid.dealersInvited",
              data.scrapBid.dealersInvited || ""
            );
            setDealersInvited(data.scrapBid.dealersInvited || "");
            setValue(
              "scrapBid.requirimentsApprovalBid",
              data.scrapBid.requirimentsApprovalBid || ""
            );
          }

          if (data.scrapContract) {
            setValue(
              "scrapInfo.contractDealer",
              data.scrapInfo.contractDealer || ""
            );
            setValue(
              "scrapContract.contractDate",
              data.scrapContract.contractDate || ""
            );
            setValue(
              "scrapContract.expirationDocument.expirationDate",
              data.scrapContract.expirationDocument?.expirationDate || ""
            );
            setExpiration(data.scrapContract.expirationDocument);
            setValue(
              "scrapContract.contractValue",
              data.scrapContract.contractValue || ""
            );
            setValue(
              "scrapContract.contractIndex",
              data.scrapContract.contractIndex || ""
            );
          }

          if (data.observations) {
            setValue(
              "observations.observations",
              data.observations.observations || ""
            );
          }
          setScrapAdmin(data);
        }
      });
    }
  }, [scrapId, setValue]);

  useEffect(() => {
    if (scrapAdmin) {
      setValue("scrapInfo.containerSize", scrapAdmin.scrapInfo.containerSize);
    }
  }, [scrapAdmin, selectedContainerType, setValue]);

  useEffect(() => {
    if (scrapAdmin) {
      setBidNegotiationType(scrapAdmin.scrapNegotiation.bidNegotiationType);
    }
  }, [scrapAdmin]);

  useEffect(() => {
    setValue("scrapContract.contractValue", "");
    if (
      selectedStatus !== "TO_DEAL" &&
      selectedStatus !== "AWAITING_BID" &&
      selectedStatus !== "IN_BID"
    ) {
      setShowContract(true);
    } else {
      setShowContract(false);
    }
  }, [selectedStatus, setValue]);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    if (scrapAdmin) {
      setTargetPrice(scrapAdmin?.scrapInfo?.targetPrice || "");
      setValue(
        "scrapNegotiation.targetPrice",
        scrapAdmin?.scrapInfo?.targetPrice || ""
      );
      setValue(
        "scrapInfo.targetPrice",
        scrapAdmin?.scrapInfo?.targetPrice || ""
      );
      setBidValue(scrapAdmin?.scrapBid?.bidValue || "");
      setValue("scrapBid.bidValue", scrapAdmin?.scrapBid?.bidValue || "");
    }
  }, [scrapAdmin, setValue]);

  const fetchEntityCountry = async () => {
    const data = await getAdrressByEntityId(id as string);
    const countryConfig = await getCountryConfigById(data.country);
    setCountryConfigEntity(countryConfig);
  };

  const fetchGenerator = async () => {
    const generatorData = await getScrapGeneratorInfo(id as string);
    setGenerator(generatorData);
  };

  const fetchContainers = async () => {
    try {
      if (countryConfigEntity && countryConfigEntity.country.length > 0) {
        const response = await getContainersByCountryName(
          countryConfigEntity.country[0]
        );
        setContainers(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAllPartnerRequirementsTotal = async () => {
    let total: string = "";
    if (countryConfigEntity?.id) {
      total = (
        await fetchPartnerRequirementsTotalByCountry(
          countryConfigEntity?.country[0]
        )
      ).length.toString();
    }
    return total;
  };

  const fetchAllFulfilledRequirementsTotal = async (id: string) => {
    let total: string = "";

    await fetchFulfilledRequirements(id).then((data) => {
      total = data?.total.toString();
    });
    return total;
  };

  useEffect(() => {
    fetchEntityCountry();
    fetchGenerator();
  }, []);

  useEffect(() => {
    if (generator) {
      setValue("scrapInfo.originName", generator?.tradingName);
    }
  }, [generator]);

  useEffect(() => {
    if (countryConfigEntity && countryConfigEntity.country.length > 0) {
      fetchContainers();
    }
  }, [countryConfigEntity]);

  const fetchData = async () => {
    if (dealersInvited) {
      const newDealers = await Promise.all(
        dealersInvited.map(async (dealer) => ({
          id: dealer.id,
          dealerName: dealer.legalName,
          inviteAccepted: false,
          fulfiledRequirements: `${
            (await fetchAllFulfilledRequirementsTotal(dealer.id)) || "0"
          }/${(await fetchAllPartnerRequirementsTotal()) || "0"}`,
          proposedValue: " ",
          attachment: " ",
          status: " ",
          inviteSended: false,
        }))
      );

      setTableData((prevData) => {
        const updatedData = prevData.filter((oldDealer) =>
          newDealers.some((newDealer) => newDealer.id === oldDealer.id)
        );

        const newItems = newDealers.filter(
          (newDealer) =>
            !updatedData.some((oldDealer) => oldDealer.id === newDealer.id)
        );

        return [...updatedData, ...newItems];
      });
    } else {
      setTableData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dealersInvited]);

  useEffect(() => {
    setValue("dealersInvitedTable.dealersInvitedList", tableData);
    setValue("scrapBid.dealersInvited", dealersInvited);
    if (scrapAdmin && id) {
      const updatedScrapAdmin = {
        ...scrapAdmin,
        dealersInvitedTable: {
          ...scrapAdmin.dealersInvitedTable,
          dealersInvitedList: tableData,
        },
        scrapBid: {
          ...scrapAdmin.scrapBid,
          dealersInvited: dealersInvited,
        },
      };
      addNewScrapAdmin(updatedScrapAdmin, id, true);
    }
  }, [tableData, scrapAdmin, id]);

  const onSubmit = async (scrapAdmin: ScrapDTO) => {
    const imageURLs: string[] = [];
    try {
      for (const image of selectedImages) {
        const storageRef = ref(storage, `scrap/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageURL = await getDownloadURL(storageRef);
        imageURLs.push(imageURL);
      }

      scrapAdmin.scrapInfo.images = imageURLs;

      if (
        (tableData && tableData.length === 0) ||
        scrapAdmin.dealersInvitedTable.dealersInvitedList.length === 0
      ) {
        await fetchData();
      }

      if (scrapId) {
        scrapAdmin.id = scrapId;
      }

      if (expiration.hasExpirationDate) {
        if (expiration.expirationDate.length === 0) {
          return setAlert({ text: `${t("alert-error-date-empty")}`, type: "error" });
        } else {
          scrapAdmin.scrapContract.expirationDocument = expiration;
        }
      }
      if (id) {
        const savedId = await addNewScrapAdmin(scrapAdmin, id);
        setAlert({
          text: scrapId
            ? t("alert-successfully-updated")
            : t("alert-successfully-registered"),
          type: "success",
        });
        if (
          scrapAdmin?.dealersInvitedTable.dealersInvitedList.length == 0 ||
          scrapId
        ) {
          navigate(`/scrap-generators-details/${id}/scraps`);
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          navigate(`/scrap-generators-details/${id}/scraps-form/${savedId}`);
        }
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  const handleCategoryChange = (category: ScrapsCategoryInfoDTO) => {
    setSelectedCategoryDetails(category);
    setValue("scrapInfo.scrapCategory", category, { shouldValidate: true });
  };

  const [containerNeeded, setContainerNeeded] = useState(true);

  const handleRadioContainerChange = (targetValue: boolean) => {
    setContainerNeeded(targetValue);
    setValue("scrapInfo.containerNeeded", targetValue);
  };

  const [bidNegotiationType, setBidNegotiationType] = useState<string>("open");
  const [responsibleShipping, setResponsibleShipping] = useState<string>("");

  const [today, setToday] = useState("");

  const [expiration, setExpiration] = useState<ExpirationDTO>({
    originId: "",
    docName: "Contrato",
    expirationDate: "",
    warningPeriod: 1,
    solved: false,
    hasExpirationDate: false,
    origin: "",
  });

  const defaultAddress: AddressInfoDTO = {
    address: "",
    state: "",
    country: "",
    countryName: "",
    city: "",
    addressNumber: "",
    postalCode: "",
    postalCodeLabel: "",
  };

  const [address, setAddress] = useState<AddressInfoDTO>(
    scrapAdmin?.scrapInfo?.operationAddress || defaultAddress
  );

  const handleDataChange = (key: string, value: any) => {
    setExpiration((contractExpirationDate: ExpirationDTO) => ({
      ...contractExpirationDate,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (expiration) {
      setValue("scrapContract.expirationDocument", expiration);
    }
  }, [expiration]);

  const getIconName = (rowItem: any) => {
    if (rowItem.inviteAccepted) return "checkmark-frame";
    if (rowItem.inviteSended) return "mail-forward";
    return "mail";
  };

  const [containerQuantityMaskedValue, setContainerQuantityMaskedValue] =
    useState("");
  const [quantityMaskedValue, setQuantityMaskedValue] = useState("");

  const handleInputChangeContainerQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const masked = applyDecimalMask(value);
    setContainerQuantityMaskedValue(masked);
  };

  const handleInputChangeQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const masked = applyDecimalMask(value);
    setQuantityMaskedValue(masked);
  };

  useEffect(() => {
    setValue("scrapInfo.quantity", containerQuantityMaskedValue);
  }, []);

  useEffect(() => {
    setValue("scrapInfo.containerQuantity", quantityMaskedValue);
  }, []);

  const handleSave = (newAddress: any) => {
    setAddress(newAddress);
    setValue("scrapInfo.operationAddress", newAddress);
  };

  const handleChangePicker = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setBidNegotiationType(value);
    setValue("scrapNegotiation.bidNegotiationType", value);
  };

  const handleChangeResponsibleShipping = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    setValue("scrapInfo.responsibleShipping", value);
    setResponsibleShipping(value);
  };

  useEffect(() => {
    if (id && !scrapId) {
      getAdrressByEntityId(id).then((data) => {
        setAddress(data as AddressInfoDTO);
        setValue("scrapInfo.operationAddress", data);
      });
    }
  }, [id]);

  useEffect(() => {
    const todayDate = new Date().toISOString().split("T")[0];
    setToday(todayDate);
  }, []);

  useEffect(() => {
    if (scrapAdmin) {
      setValue("scrapInfo.scrapCategory", scrapAdmin?.scrapInfo?.scrapCategory);
    }
  }, [scrapAdmin, setValue]);

  useEffect(() => {
    if (!containerNeeded) {
      setValue("scrapInfo.containerType", "");
      setValue("scrapInfo.containerSize", "");
      setValue("scrapInfo.containerQuantity", "");
    }
  }, [containerNeeded, setValue]);

  const selectedContainer = containers.find(
    (container) => container.name === selectedContainerType
  );

  const getAttachmentText = (attachments: string[]) => {
    let attachmentString: string = "";

    if (attachments.length === 1 && attachments[0].trim() === "") {
      attachments = [];
    }

    if (attachments.length > 0) {
      const attachmentCount = attachments.length;
      attachmentString = `${t("label-attachment")} (${attachmentCount})`;
    } else {
      attachmentString = "-";
    }

    return attachmentString;
  };

 if (enabledUsers.includes(profile || "public")) {
  return (
    <div className="page-centered">
      {editTableModalOpen && editTableItem && (
        <div className="modal-background">
          <ScrapDealersInvitedModalForm
            countryConfigEntity={countryConfigEntity}
            tableData={editTableItem}
            onClose={handleCloseTableModal}
            id={scrapId || ""}
          />
        </div>
      )}
      {isModalOpen && (
        <UploadCsvModal
          onClose={handleCloseModal}
          data={data}
          apiData={"importdealersprivatebid"}
          templateUrl={`https://firebasestorage.googleapis.com/v0/b/rbprj-100132.appspot.com/o/templates%2F${"importdealersprivatebid"}.csv?alt=media`}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields">
          <CollapsibleSection title={t("material-about-material")}>
            <div className="flex-column margin-topbottom-1">
              <ScrapCategoryInputAutocomplete
                label={t("label-scrap-category")}
                placeholder={t("label-scrap-category")}
                onChange={handleCategoryChange}
                initialValue={
                  scrapAdmin &&
                  scrapAdmin.scrapInfo &&
                  scrapAdmin.scrapInfo.scrapCategory
                }
              />
              {!selectedCategoryDetails && isSubmitted && (
                <span>{t("error-input-required")}</span>
              )}
              {selectedCategoryDetails && (
                <div className="flex-row info-section">
                  <img
                    style={{
                      height: "6rem",
                      width: "8rem",
                      overflow: "hidden",
                      marginRight: "1rem",
                    }}
                    src={
                      selectedCategoryDetails.image
                        ? selectedCategoryDetails.image
                        : genericImage
                    }
                    alt="material image"
                  />
                  <div style={{ width: "100%" }} className="flex-column">
                    <h2>{selectedCategoryDetails.name}</h2>
                    <div className="custom-line-hr"></div>
                    <p style={{ marginBottom: "0.4rem" }}>
                      {t("label-description")}:{" "}
                      <strong>
                        {selectedCategoryDetails.description || "-"}
                      </strong>
                    </p>
                    <p>
                      {t("label-composition")}:{" "}
                      <strong>
                        {selectedCategoryDetails.composition || "-"}
                      </strong>
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="flex-column margin-topbottom-1">
              <label htmlFor="material-special-description">
                {t("material-special-description")}
              </label>
              <input
                placeholder={t("material-placeholder-special-description")}
                type="text"
                id="material-special-description"
                {...register("scrapInfo.specialDescription", {
                  required: false,
                })}
              />
            </div>

            <div className="flex-column">
              <label htmlFor="imageUpload" className="custom-file-upload title">
                {t("label-material-pic")}
              </label>
              <div className="input-and-preview">
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                />
                <div className="image-previews">
                  {previews.map((preview, index) => (
                    <div key={index} className="image-selected">
                      <img
                        className="preview-img"
                        src={preview}
                        alt={`Preview ${index}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CollapsibleSection>

          <CollapsibleSection title={t("material-about-operation")}>
            <div className="flex-column margin-topbottom-1">
              <label htmlFor="material-quantity">
                {t("material-quantity")}
              </label>
              <div className="flex-row double-fields">
                <div className="flex-column">
                  <input
                    placeholder={t("material-quantity-simple")}
                    type="text"
                    value={quantityMaskedValue}
                    id="material-quantity-simple"
                    {...register("scrapInfo.quantity", {
                      required: true,
                    })}
                    onChange={handleInputChangeQuantity}
                  />
                  {errors.scrapInfo?.quantity && isSubmitted && (
                    <span>{t("error-input-required")}</span>
                  )}
                </div>

                <div className="flex-column">
                  <select
                    id="material-quantity-unity"
                    {...register("scrapInfo.unity", { required: true })}
                    onChange={handleUnityChange}
                  >
                    <option value=""></option>
                    {Object.entries(unityENUM).map(([key, value]) => (
                      <option key={key} value={key}>
                        {t(`unityENUM.${value}`)}
                      </option>
                    ))}
                  </select>
                  {errors.scrapInfo?.unity && isSubmitted && (
                    <span>{t("error-input-required")}</span>
                  )}
                </div>
                <div className="flex-column">
                  <select
                    id="material-quantity-period"
                    {...register("scrapInfo.period", { required: true })}
                  >
                    <option value=""></option>
                    {Object.entries(periodENUM).map(([key, value]) => (
                      <option key={key} value={key}>
                        {t(`periodENUM.${value}`)}
                      </option>
                    ))}
                  </select>
                  {errors.scrapInfo?.period && isSubmitted && (
                    <span>{t("error-input-required")}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex-column">
              <label htmlFor="material-pick-up-frequency">
                {t("material-pick-up-frequency")}
              </label>
              <select
                id="material-pick-up-frequency"
                {...register("scrapInfo.pickUpFrequency", { required: true })}
              >
                <option value=""></option>
                {Object.entries(periodENUM).map(([key, value]) => (
                  <option key={key} value={key}>
                    {t(`periodENUM.${value}`)}
                  </option>
                ))}
              </select>
              {errors.scrapInfo?.pickUpFrequency && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <AddressSectionFields
              initialValues={address}
              onSave={handleSave}
            ></AddressSectionFields>

            <div className="flex-column">
              <label htmlFor="responsible-shipping">
                {t("label-responsible-shipping")}
              </label>
              <select onChange={handleChangeResponsibleShipping}>
                <option value={""}></option>
                <option
                  selected={
                    scrapAdmin?.scrapInfo?.responsibleShipping === "generator"
                  }
                  value={"generator"}
                >
                  {t("label-generator")}
                </option>
                <option
                  selected={
                    scrapAdmin?.scrapInfo?.responsibleShipping === "buyer"
                  }
                  value={"buyer"}
                >
                  {t("label-buyer")}
                </option>
              </select>
              {responsibleShipping === "" && isSubmitted && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column">
              <h4>{t("material-does-container")}</h4>
              <div className="flex-row radio-types">
                <div className="flex-row">
                  <label
                    style={{ margin: "10px 0px" }}
                    htmlFor="material-container"
                  >
                    <input
                      type="radio"
                      id="material-container"
                      value="true"
                      onChange={(e) => handleRadioContainerChange(true)}
                      checked={containerNeeded === true}
                    />
                    {t("label-need")}
                  </label>
                </div>
                <div className="flex-row">
                  <label style={{ margin: "10px" }} htmlFor="isBranch">
                    <input
                      type="radio"
                      id="isBranch"
                      value="false"
                      onChange={() => handleRadioContainerChange(false)}
                      checked={containerNeeded === false}
                    />
                    {t("label-non-need")}
                  </label>
                </div>
                {errors.scrapInfo?.containerNeeded === null &&
                  scrapAdmin?.scrapInfo?.containerNeeded && (
                    <span>{t("error-input-required")}</span>
                  )}
              </div>
            </div>

            {containerNeeded === true && (
              <div className="flex-column">
                <div className="flex-row double-fields">
                  <div className="flex-column">
                    <label htmlFor="material-container-type">
                      {t("material-container-type")}
                    </label>
                    <select
                      id="material-container-type"
                      {...register("scrapInfo.containerType", {
                        required: true,
                        onChange: (e) =>
                          setSelectedContainerType(e.target.value),
                      })}
                    >
                      <option value=""></option>
                      {containers.map((container, index) => (
                        <option key={index} value={container.name}>
                          {container.name}
                        </option>
                      ))}
                    </select>
                    {errors.scrapInfo?.containerType && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <label htmlFor="material-container-size">
                      {t("material-container-size")}
                    </label>
                    <select
                      id="material-container-size"
                      {...register("scrapInfo.containerSize", {
                        required: true,
                      })}
                    >
                      <option value=""></option>
                      {selectedContainer?.size.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                    {errors.scrapInfo?.containerSize && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <label htmlFor="material-container-quantity">
                      {t("material-container-quantity")}
                    </label>
                    <input
                      type="text"
                      value={containerQuantityMaskedValue}
                      id="material-container-quantity"
                      {...register("scrapInfo.containerQuantity", {
                        required: true,
                      })}
                      onChange={handleInputChangeContainerQuantity}
                    />
                    {errors.scrapInfo?.containerQuantity && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </CollapsibleSection>

          <CollapsibleSection title={t("material-about-negotiation")}>
            <div className="flex-column">
              <label htmlFor="material-price-expectancy">
                {t("material-price-expectancy")}
                <span style={{ marginLeft: 5, color: "#9e9e9e" }}>
                  {`(${countryConfigEntity?.currencyName}/${
                    selectedUnity || "unity"
                  })`}
                </span>
              </label>
              <CurrencyInput
                currency={countryConfigEntity?.currency}
                locale={countryConfigEntity?.locale}
                defaultValue={targetPrice}
                onChangeValue={(event, originalValue, maskedValue) => {
                  setTargetPrice(maskedValue.toString());
                  setValue(
                    "scrapNegotiation.targetPrice",
                    maskedValue.toString(),
                    {
                      shouldValidate: true,
                    }
                  );
                }}
                InputElement={
                  <input
                    placeholder={t("material-price-expectancy")}
                    type="text"
                    id="targetPrice"
                    {...register("scrapNegotiation.targetPrice", {
                      required: true,
                    })}
                  />
                }
              />
              {errors.scrapNegotiation?.targetPrice && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div style={{ marginTop: "1rem" }} className="flex-column">
              <div className="flex-column">
                <label htmlFor="material-sales-date-final">
                  {t("material-sales-date")}
                </label>
                <input
                  type="date"
                  min={today}
                  id="material-sales-date-final"
                  {...register("scrapNegotiation.salesDate", {
                    required: true,
                  })}
                />
                {errors.scrapNegotiation?.salesDate && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            </div>

            <div className="double-fields">
              <div style={{ marginTop: "1rem" }} className="flex-column">
                <label htmlFor="material-negotiation-type">
                  {t("material-negotiation-type")}
                </label>
                <select
                  value={bidNegotiationType}
                  onChange={handleChangePicker}
                >
                  <option value={"open"}>{t("label-open-bid")}</option>
                  <option value={"closed"}>{t("label-closed-bid")}</option>
                </select>
              </div>
              {bidNegotiationType === "closed" && (
                <div style={{ marginTop: "0.62rem" }} className="flex-row">
                  <button
                    type="button"
                    title={t("label-upload-csv")}
                    onClick={handleOpenModal}
                    className="neutral-white white-with-icon button ml-10"
                  >
                    <img src={uploadIcon} alt={t("label-upload-csv")} />
                  </button>

                  <label htmlFor="material-add-prefered-suppliers">
                    {t("material-add-prefered-suppliers")}
                  </label>
                </div>
              )}
            </div>
          </CollapsibleSection>

          <CollapsibleSection title={t("material-about-bid")}>
            <div className="flex-column margin-topbottom-1">
              <label htmlFor="material-bid-status">
                {t("material-bid-status")}
              </label>
              <select
                defaultValue={scrapAdmin?.scrapInfo.status}
                id="material-bid-status"
                {...register("scrapInfo.status", {
                  required: true,
                })}
                onChange={handleStatusChange}
              >
                {Object.values(StatusEnum).map((status) => (
                  <option key={status} value={status}>
                    {t(`statusEnum.${status}`)}
                  </option>
                ))}
              </select>
              {errors.scrapInfo?.status && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column margin-topbottom-1">
              <label htmlFor="material-bid-type">
                {t("material-bid-type")}
              </label>
              <select
                id="material-bid-type"
                {...register("scrapBid.bidType", { required: false })}
              >
                <option value=""></option>
                {Object.values(MaterialBidTypeEnum).map((type) => (
                  <option key={type} value={type}>
                    {t(`bidTypeEnum.${type}`)}
                  </option>
                ))}
              </select>
              {errors.scrapBid?.bidType && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column margin-topbottom-1">
              <label htmlFor="material-bid-date">
                {t("material-bid-date")}
              </label>
              <input
                placeholder={t("material-bid-date")}
                type="date"
                min={today}
                id="material-bid-date"
                {...register("scrapBid.bidDate", {
                  required: false,
                })}
              />
              {errors.scrapBid?.bidDate && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column margin-topbottom-1">
              <label htmlFor="material-bid-value">
                {t("material-bid-value")}
                <span style={{ marginLeft: 5, color: "#9e9e9e" }}>
                  {`(${countryConfigEntity?.currencyName}/${
                    selectedUnity || "unity"
                  })`}
                </span>
              </label>
              <CurrencyInput
                currency={countryConfigEntity?.currency}
                locale={countryConfigEntity?.locale}
                defaultValue={scrapAdmin?.scrapBid?.bidValue}
                onChangeValue={(event, originalValue, maskedValue) => {
                  setBidValue(maskedValue.toString());
                  setValue("scrapBid.bidValue", maskedValue.toString(), {
                    shouldValidate: true,
                  });
                }}
                InputElement={
                  <input
                    placeholder={t("material-bid-value")}
                    type="text"
                    id="material-bid-value"
                    {...register("scrapBid.bidValue", {
                      required: false,
                    })}
                  />
                }
              />
              {errors.scrapBid?.bidValue && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            <div className="flex-column margin-topbottom-1">
              <label htmlFor="material-dealers-invited">
                {t("material-dealers-invited")}
              </label>
              <ScrapDealerInputAutocomplete
                onChange={(data) => {
                  setValue("scrapBid.dealersInvited", data);
                  setDealersInvited(data);
                }}
                placeholder={t("material-dealers-invited")}
                defaultValue={scrapAdmin?.scrapBid?.dealersInvited}
              ></ScrapDealerInputAutocomplete>
              {errors.scrapBid?.dealersInvited && (
                <span>{t("error-input-required")}</span>
              )}
            </div>

            {tableData.length !== 0 && scrapId && (
              <div className="flex-column info-section">
                <div className="flex-row" style={{ justifyContent: "end" }}>
                  <SparkIcon icName="cloud-upload" noPadding></SparkIcon>
                </div>
                <SparkTable>
                  <SparkTableHead
                    className="flex-row"
                    style={{ alignItens: "center" }}
                  >
                    <SparkTableRow>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell
                          style={{
                            alignItens: "center",
                            justifyContent: index != 0 ? "center" : "start",
                            display: "flex",
                            flexDirection: "row",
                            margin: "1rem 0",
                          }}
                          key={index}
                        >
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {tableData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={(e) => handleOpenTableModal(rowItem)}
                      >
                        <SparkTableCell>{rowItem.dealerName}</SparkTableCell>
                        <SparkTableCell
                          className="flex-row"
                          style={{ justifyContent: "center" }}
                        >
                          {rowItem.fulfiledRequirements}
                        </SparkTableCell>

                        <SparkTableCell
                          className="flex-row"
                          style={{ justifyContent: "center" }}
                        >
                          <SparkIcon
                            className="icon-flag"
                            pallete="tertiary"
                            no-padding={true}
                            icName={getIconName(rowItem)}
                          />
                          <p style={{ color: "white" }}>{"-----"}</p>
                        </SparkTableCell>

                        <SparkTableCell
                          className="flex-row"
                          style={{ justifyContent: "center" }}
                        >
                          {rowItem.proposedValue}
                        </SparkTableCell>
                        <SparkTableCell
                          className="flex-row"
                          style={{ justifyContent: "center" }}
                        >
                          {getAttachmentText(rowItem.attachment)}
                        </SparkTableCell>
                        <SparkTableCell
                          className="flex-row"
                          style={{ justifyContent: "center" }}
                        >
                          <p>
                            {rowItem.status != " "
                              ? t(`dealer-invited-statusENUM.${rowItem.status}`)
                              : "-"}
                          </p>
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                  <br />
                </SparkTable>
              </div>
            )}

            <div className="form-fields flex-column">
              <div className="flex-column">
                <label htmlFor="requiriments-approval-bid">
                  {t("label-requiriments-approval-bid")}
                </label>
                <textarea
                  placeholder={t("placeholder.requirimentsApprovalBid")}
                  id="requiriments-approval-bid"
                  {...register("scrapBid.requirimentsApprovalBid")}
                />
              </div>
            </div>
          </CollapsibleSection>

          {showContract && (
            <CollapsibleSection title={t("material-about-contract")}>
              <div className="flex-column margin-topbottom-1">
                <label htmlFor="material-dealer-contracted">
                  {t("material-dealer-contracted")}
                </label>
                <select
                  id="material-dealer-contracted"
                  {...register("scrapInfo.contractDealer", {
                    required: false,
                  })}
                >
                  <option value={""}></option>
                  {dealersInvited.map((dealer: any) => (
                    <option key={dealer.id} value={dealer.id}>
                      {dealer.legalName} - {dealer.tradingName}
                    </option>
                  ))}
                </select>
                {errors.scrapInfo?.contractDealer && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column margin-topbottom-1">
                <label htmlFor="material-contract-date">
                  {t("material-contract-date")}
                </label>
                <input
                  placeholder={t("material-contract-date")}
                  type="date"
                  id="material-contract-date"
                  {...register("scrapContract.contractDate", {
                    required: false,
                  })}
                />
                {errors.scrapContract?.contractDate && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column margin-topbottom-1">
                <Expiration
                  dto={expiration}
                  dataHandler={handleDataChange}
                  expirationDate={"expirationDate"}
                  warningPeriod={"warningPeriod"}
                  hasExpirationDate={"hasExpirationDate"}
                  hasExpirationDateInit={expiration.hasExpirationDate || false}
                  expirationDateInit={expiration.expirationDate || ""}
                  warningPeriodInit={expiration.warningPeriod || 0}
                />

                {errors.scrapContract?.expirationDocument?.expirationDate && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column margin-topbottom-1">
                <label htmlFor="material-contract-value">
                  {t("material-contract-value")}
                  <span style={{ marginLeft: 5, color: "#9e9e9e" }}>
                    {`(${countryConfigEntity?.currencyName}/${
                      selectedUnity || "unity"
                    })`}
                  </span>
                </label>
                <CurrencyInput
                  currency={countryConfigEntity?.currency}
                  locale={countryConfigEntity?.locale}
                  defaultValue={scrapAdmin?.scrapContract?.contractValue}
                  onChangeValue={(event, originalValue, maskedValue) => {
                    setContractValue(maskedValue.toString());
                    setValue(
                      "scrapContract.contractValue",
                      maskedValue.toString(),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                  InputElement={
                    <input
                      placeholder={t("material-contract-value")}
                      type="text"
                      id="material-contract-value"
                      {...register("scrapContract.contractValue", {
                        required: false,
                      })}
                    />
                  }
                />
                {errors.scrapContract?.contractValue && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>

              <div className="flex-column margin-topbottom-1">
                <label htmlFor="material-contract-index">
                  {t("material-contract-index")}
                </label>
                <input
                  placeholder={t("material-contract-index")}
                  type="text"
                  id="material-contract-index"
                  {...register("scrapContract.contractIndex", {
                    required: false,
                  })}
                />
                {errors.scrapContract?.contractIndex && (
                  <span>{t("error-input-required")}</span>
                )}
              </div>
            </CollapsibleSection>
          )}

          <CollapsibleSection title={t("label-others")}>
            <div className="form-fields flex-column">
              <div className="flex-column">
                <label htmlFor="observations">
                  {t("label-general-observations")}
                </label>
                <textarea
                  placeholder={t("placeholder.observations")}
                  id="observations"
                  {...register("observations.observations")}
                />
              </div>
            </div>
          </CollapsibleSection>

          <div className="flex-row buttons-section">
            <button
              className="neutral-white"
              onClick={() => navigate(`/scrap-generators-details/${id}/scraps`)}
              type="button"
            >
              {t("label-back")}
            </button>
            <button className="blue" type="submit">
              {t("label-save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
    } else {
      navigate("/page-not-found")
      return <></>
    }
};

export default ScrapForm;

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  EmailsWarningDTO,
  EmailsWarningInfoDTO,
  ObservationsDTO,
} from "./models/emails-warning-DTO";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";
import { saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";

// add new
export async function addNew(data: EmailsWarningDTO, id: string) {

  if (data.id == undefined) {
    data.userInfo.originId = id;
    saveNewAsync(COLLECTIONS.EMAIL_WARNING, data.userInfo).then(
      (docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
    });
  } else {
    update(COLLECTIONS.EMAIL_WARNING, data.userInfo, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
  }
}

export async function getEmailsWarningsById(
  id: string
): Promise<EmailsWarningInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.EMAIL_WARNING);

  const q = query(collectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as EmailsWarningInfoDTO[];
  return items;
}

export const getEmailWarningById = async (
  id: string
): Promise<EmailsWarningDTO | null> => {
  const userInfo = await getEmailWarning(id);
  const observations = await getObservation(id);

  return {
    id,
    userInfo,
    observations,
  };
};

export async function getEmailWarning(
  id: string
): Promise<EmailsWarningInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.EMAIL_WARNING, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as EmailsWarningInfoDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import {
  PartnerRequirementInfoDTO,
  PartnerRequirementDTO,
  ObservationsDTO,
} from "./partner-requirement-DTO";
import { saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { setExpirationDocument } from "../../../../components/Expiration/ExpirationService";

import { ScrapDealerInfoDTO } from "../../settings-DTO";
import { firestore } from "../../../../utils/firebase-config";


// add new
export async function addNew(data: PartnerRequirementDTO, id: string) {
  
  if (data.id == undefined) {
    data.partnerRequirementInfoDTO.originId = id;
    saveNewAsync(COLLECTIONS.PARTNER_REQUIREMENTS, data.partnerRequirementInfoDTO).then(
      (docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      }
    );
  } else {
    update(COLLECTIONS.PARTNER_REQUIREMENTS, data.partnerRequirementInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
  }
}

async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

export async function getPartnerRequirementByOriginId(
  id: string
): Promise<PartnerRequirementInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.PARTNER_REQUIREMENTS);

  const q = query(collectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as PartnerRequirementInfoDTO[];
  return items;
}

export const getById = async (
  id: string
): Promise<PartnerRequirementDTO | null> => {
  const partnerRequirementInfoDTO = await getInfoById(id);
  const observations = await getObservation(id);

  return {
    id,
    partnerRequirementInfoDTO,
    observations,
  };
};

export async function getInfoById(id: string): Promise<PartnerRequirementInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.PARTNER_REQUIREMENTS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as PartnerRequirementInfoDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

import React from "react";
import "../../styles/privacy-notice.css";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PrivacyNotice: React.FC<{}> = ({ }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="notice">
        <span className="text-notice">{t("privacy.title-notice")}</span>
        <span className="text">{t("privacy.are")}</span>
      </div>
      <div className="notice">
        <span className="title-privacy-note">{t("privacy.title-one")}</span>
        <span className="text">{t("privacy.privacy")}</span>

        <span className="text">{t("privacy.protection")}</span>
      </div>
      <div className="notice">
        <span className="title-privacy-note">{t("privacy.title-two")}</span>
        <span className="text">{t("privacy.controller")}</span>

        <span className="text">{t("privacy.details")}</span>

        <div className="notice">
          <span className="texts">{t("privacy.name")}</span>
          <span className="texts">{t("privacy.address")}</span>
          <span className="texts">{t("privacy.city")}</span>
          <span className="texts">{t("privacy.cep")}</span>
          <span className="texts">{t("privacy.phone")}</span>
          <span className="texts">{t("privacy.email")}</span>
        </div>
      </div>
      <div className="notice">
        <span className="title-privacy-note">{t("privacy.title-three")}</span>
        <span className="text-subtitle">{t("privacy.subtitle-one")}</span>
        <span className="subtitle">{t("privacy.categories")}</span>
        <ul className="circle">
          <li>{t("privacy.communication")}</li>
          <li>{t("privacy.contractual")}</li>
          <li>{t("privacy.historic")}</li>
          <li>{t("privacy.accounting")}</li>
          <li>{t("privacy.planning")}.</li>
          <li>{t("privacy.geolocation")}</li>
        </ul>
        <span className="text-subtitle">{t("privacy.subtitle-two")}</span>
        <span className="subtitle">{t("privacy.data")}</span>
        <span className="subtitle">{t("privacy.deal")}</span>
        <span className="text-subtitle">{t("privacy.subtitle-three")}</span>
        <span className="subtitle">{t("privacy.services")}</span>
        <span className="subtitles">{t("privacy.subtitle-four")}</span>
        <span className="subtitles-regular">{t("privacy.text")}</span>
        <span className="subtitles">{t("privacy.subtitle-five")}</span>
        <span className="subtitles-regular">{t("privacy.lawful")}</span>
        <span className="text-subtitle">{t("privacy.subtitle-six")}</span>
        <span className="subtitle">{t("privacy.benefits")}</span>
        <span className="text-privacy">{t("privacy.subtitle-seven")}</span>
        <span className="subtitle">{t("privacy.internet")}</span>
        <span className="subtitle">{t("privacy.log")}</span>
        <span className="subtitle">{t("privacy.log-files")}</span>
        <ul className="circle">
          <li>{t("privacy.IP-adress")}</li>
          <li>{t("privacy.internet-address")}</li>
          <li>{t("privacy.provider-name")}</li>
          <li>{t("privacy.name-files")}</li>
          <li>{t("privacy.date")}</li>
          <li>{t("privacy.qtd-data")}</li>
          <li>{t("privacy.operational-system")}</li>
          <li>{t("privacy.status-code")}</li>
        </ul>
        <span style={{ marginLeft: 25, fontWeight: "bold" }} className="text">
          {t("privacy.subtitle-eight")}
        </span>
        <span className="subtitle">{t("privacy.offer")}</span>
        <span style={{ marginLeft: 25, fontWeight: "bold" }} className="text">
          {t("privacy.shared")}
        </span>
        <span className="subtitle">{t("privacy.personal-data")}</span>
        <span className="subtitle">{t("privacy.transferred-parties")}</span>
        <span className="subtitles">{t("privacy.subtitle-nine")}</span>
        <span className="subtitles-regular">{t("privacy.providers")}</span>
        <span className="text-privacy">{t("privacy.subtitle-ten")}</span>
        <span className="subtitle">{t("privacy.transfer")}</span>
        <span className="text-privacy">{t("privacy.storage-duration")}</span>
        <span className="subtitle">{t("privacy.store")}</span>
      </div>

      <div className="notice">
        <span className="title-privacy-note">{t("privacy.title-cookies")}</span>
        <span className="text">{t("privacy.service-online")}</span>
        <span className="text">{t("privacy.description-cookies")}</span>
        <span className="text">{t("privacy.tracking")}</span>

        <span className="text-privacy">4.1. Categorias</span>
        <span className="subtitle">
          Distinguimos entre cookies que são tecnicamente necessários para as
          funções do serviço on-line e cookies e mecanismos de rastreamento que
          não são tecnicamente necessários para a função do serviço on-line.
        </span>

        <span className="subtitle">
          Em geral, é possível utilizar o serviço on-line sem cookies que sirvam
          a finalidades não técnicas.
        </span>

        <span className="subtitles">
          {t("privacy.cookies-technically")}
        </span>
        <span className="subtitles-regular">{t("privacy.cookies")}</span>
        <span className="subtitles-regular">{t("privacy.delete-cookies")}</span>
        <span className="subtitles">{t("privacy.cookies-marketing")}</span>
        <span className="subtitles-regular">{t("privacy.name-provider")}</span>
        <span className="subtitles-regular">{t("privacy.provider")}</span>
        <span className="subtitles-regular">{t("privacy.function")}</span>
        <span className="subtitles-regular">{t("privacy.subcategories")}</span>
        <span className="text-privacy">{t("privacy.title-ten")}</span>
        <span className="subtitle">{t("privacy.manage-settings")}</span>
        <span className="subtitle">{t("privacy.note")}</span>
        <span className="text-privacy">{t("privacy.disabling-cookies")}</span>
        <span className="subtitle">{t("privacy.disable-cookies")}</span>
        <span className="text-privacy">{t("privacy.config-cookies")}</span>
        <span className="subtitle">{t("privacy.use-cookies")}</span>
        <span className="subtitle">{t("privacy.config")}</span>
        <span className="subtitle">{t("privacy.document")}</span>
      </div>

      <div className="notice">
        <span className="title-privacy-note">
          {t("privacy.use-applications")}
        </span>
        <span className="text">{t("privacy.app")}</span>
        <span className="subtitles">
          {t("privacy.processing")}
        </span>
        <span className="subtitles-regular">{t("privacy.no-data")}</span>
      </div>
      <div className="notice">
        <span className="title-privacy-note">
          {t("privacy.external-links")}
        </span>
        <span className="text">{t("privacy.link")}</span>
      </div>
      <div className="notice">
        <span style={{ marginTop: 28 }} className="title-privacy-note">
          {t("privacy.security")}
        </span>
        <span className="text">{t("privacy.employees")}</span>
        <span className="text">{t("privacy.technical-measures")}</span>
      </div>
      <div className="notice">
        <span style={{ marginTop: 28 }} className="title-privacy-note">
          {t("privacy.user-rights")}
        </span>
        <span className="text">{t("privacy.identification")}</span>
        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.confirmation")}
        </span>
        <span className="text">{t("privacy.right-personal-data")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.incomplete-data")}
        </span>
        <span className="text">{t("privacy.contest")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.right-anonymity")}
        </span>
        <span className="text">{t("privacy.lgpd")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.data-portability")}
        </span>
        <span className="text">{t("privacy.request")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.delete-data")}
        </span>
        <span className="text">{t("privacy.request-deletion")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.right-object")}
        </span>
        <span className="text">{t("privacy.personal-data-lgpd")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.third-information")}
        </span>
        <span className="text">{t("privacy.third")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.info-consent")}
        </span>
        <span className="text">{t("privacy.data-personal")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.delete-consent")}
        </span>
        <span className="text">{t("privacy.consented")}</span>

        <span style={{ fontWeight: "bold" }} className="text">
          {t("privacy.revision")}
        </span>
        <span className="text">{t("privacy.request-review")}</span>
      </div>

      <div className="notice">
        <span style={{ marginTop: 28 }} className="title-privacy-note">
          {t("privacy.titles-one")}
        </span>
        <span className="text">{t("privacy.right-register")}</span>
        <a href={t("privacy.links")}>{t("privacy.links")}</a>
      </div>
      <div className="notice">
        <span style={{ marginTop: 28 }} className="title-privacy-note">
          {t("privacy.title-five")}
        </span>
        <span className="text">{t("privacy.change")}</span>
      </div>
      <div className="notice">
        <span style={{ marginTop: 28 }} className="title-privacy-note">
          {t("privacy.title-six")}
        </span>
        <span className="text">{t("privacy.text-contact")}</span>
        <span className="text">{t("privacy.claims")}</span>

        <div className="notice">
          <span className="texts">{t("privacy.data-security")}</span>
          <span className="texts">{t("privacy.ceps")}</span>
          <span className="texts">{t("privacy.addresss")}</span>
          <span className="texts">{t("privacy.country")}</span>
          <span className="texts">{t("privacy.emails")}</span>
          <span className="texts">{t("privacy.fone")}</span>
        </div>
      </div>
    </div>
  );
};

export default PrivacyNotice;

import React, { useState, useEffect } from "react";
import "./Alert.css";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";

export interface AlertProps {
  text: string;
  type: "info" | "success" | "warning" | "error";
}

const Alert: React.FC<AlertProps> = ({ text, type }) => {
  const [visible, setVisible] = useState(true);
  const [animationClass, setAnimationClass] = useState("show");

  useEffect(() => {
    const hideTimer = setTimeout(() => {
      setAnimationClass("hide");
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }, 4500);

    return () => {
      clearTimeout(hideTimer);
    };
  }, []);

  return visible ? (
    <div className={`alert ${type} ${animationClass}`}>
      {getIcon(type)}
      <div className="text">{text}</div>
    </div>
  ) : null;
};

const getIcon = (type: string) => {
  switch (type) {
    case "info":
      return <SparkIcon icName="info-i" pallete="primary" />;
    case "success":
      return <SparkIcon icName="checkmark-frame" pallete="primary" />;
    case "warning":
      return <SparkIcon icName="alert-warning" pallete="primary" />;
    case "error":
      return <SparkIcon icName="alert-error" pallete="primary" />;
    default:
      return null;
  }
};

export default Alert;

import { useTranslation } from "react-i18next";
import iconEmail from "../../../../assets/img/email.svg";

const IconEmail: React.FC = () => {
  const { t } = useTranslation();
  const subject = t("mail.subject");
  const body = t("mail.body");
  const mailtoLink = `mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <a
      className="email-link"
      href={mailtoLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="icon-email" src={iconEmail} alt="email" height={50} width={50} />
    </a>
  );
};

export default IconEmail;

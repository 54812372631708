import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import {
  ContainerTypesInfoDTO,
  ContainerTypesDTO,
  ObservationsDTO,
} from "./container-types-DTO";
import { saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { ScrapDealerInfoDTO } from "../../settings-DTO";
import { firestore } from "../../../../utils/firebase-config";


// add new
export async function addNew(data: ContainerTypesDTO, id: string) {
  data.containerTypesInfoDTO.size = data.containerTypesInfoDTO.sizeString.replace(", ", ",").split(",");
  if (data.id == undefined) {
    data.containerTypesInfoDTO.originId = id;
    saveNewAsync(COLLECTIONS.CONTAINERS_TYPE, data.containerTypesInfoDTO).then(
      (docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      }
    );
  } else {
    update(COLLECTIONS.CONTAINERS_TYPE, data.containerTypesInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
  }
}

export async function getContainerTypesByOriginId(
  id: string
): Promise<ContainerTypesInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.CONTAINERS_TYPE);

  const q = query(collectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ContainerTypesInfoDTO[];
  return items;
}

export const getById = async (
  id: string
): Promise<ContainerTypesDTO | null> => {
  const containerTypesInfoDTO = await getInfoById(id);
  const observations = await getObservation(id);

  return {
    id,
    containerTypesInfoDTO,
    observations,
  };
};

export async function getInfoById(id: string): Promise<ContainerTypesInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.CONTAINERS_TYPE, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ContainerTypesInfoDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserForm from "../users-form/user-form";
import { UserInfoDTO } from "../models/users-DTO";

interface Props {
  item: UserInfoDTO;
  onClose: () => void;
}

const CardUser: React.FC<Props> = ({ onClose, item }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose()
  };

  return (
    <div>
      {isModalOpen && (
      <div className="modal-background">
        <UserForm userId={item.id} onClose={handleCloseModal}/>
      </div>
    )}
    <div
      className="flex-row card-dealer"
      onClick={handleOpenModal}
    >

        <div className="flex-column infos">
          <h3 style={{margin:0}}>{item.name || "-"}</h3>
            <div className="custom-line-hr"/>
          <p>
            {t("label-email")}: <strong>{item.email || "-"}</strong>
          </p>
          <p>
            {t("label-profile")}:{" "}
            <strong>{item.profile || "-"}</strong>
          </p>
          <p>
            {t("label-last-login")}:{" "}
            <strong>{item.lastAccess || "-"}</strong>
          </p>
        </div>

    </div>
    </div>
  );
};

export default CardUser;

import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { PartnerRequirementInfoDTO } from "./partner-requirement-DTO";
import { getPartnerRequirementByOriginId } from "./partner-requirement-service";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import PartnerRequirementForm from "./partner-requirement-form/partner-requirement-form";
import { useAuth } from "../../../../contexts/auth-context";

const PartnerRequirement: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [partnerRequirement, setPartnerRequirement] = useState<
    PartnerRequirementInfoDTO[]
  >([]);
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-requirement")}`,
    `${t("label-document-upload-required")}`,
    `${t("label-document-approval-demanded")}`,
    `${t("label-document-expiring-period")} (${t("days")})`,
  ];

  const fetchPartnerRequirement = async () => {
    if (id) {
      try {
        const data = await getPartnerRequirementByOriginId(id);
        setPartnerRequirement(data);

        setTableData(data);
      } catch (error) {
        console.error("Error fetching scrap categories: ", error);
      }
    }
  };
  const [requirementId, setRequirementID] = useState(null || "");

  useEffect(() => {
    fetchPartnerRequirement();
  }, [id, setPartnerRequirement]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchPartnerRequirement();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <PartnerRequirementForm requirementId={requirementId ?? ""} onClose={handleCloseModal}
          /></div>
        )}
        <div className="page">
          <div className="flex-row" >
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
          </div>
          <br />
          <div className="info-section">
          <SparkTable>
            <SparkTableHead className="flex-row" style={{ alignItens: "center" }}>
              <SparkTableRow>
                {tableHeader.map((header, index) => (
                  <SparkTableCell style={{ alignItens: "center" }} key={index}>
                    <strong>{header.toUpperCase()}</strong>
                  </SparkTableCell>
                ))}
              </SparkTableRow>
            </SparkTableHead>

            <SparkTableBody>
              {tableData.map((rowItem: any, rowIndex: any) => (
                <SparkTableRow onClick={() => {
                  setRequirementID(rowItem.id);
                  handleOpenModal();
                }}

                 key={rowIndex}>
                  <SparkTableCell>{rowItem.requirement}</SparkTableCell>
                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    <SparkIcon
                      className="icon-flag"
                      pallete="tertiary"
                      no-padding={true}
                      icName={
                        rowItem.documentUploadRequired
                          ? "checkmark-frame"
                          : "less-minimize"
                      }
                    />
                  </SparkTableCell>

                <SparkTableCell
                  className="flex-row"
                  style={{ justifyContent: "center" }}
                >
                  <SparkIcon
                    className="icon-flag"
                    pallete="tertiary"
                    no-padding={true}
                    icName={
                      rowItem.documentApprovalRequired
                        ? "checkmark-frame"
                        : "less-minimize"
                    }
                  />
                </SparkTableCell>

                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    {t(`${rowItem.expirationPeriod}`)}
                  </SparkTableCell>
                </SparkTableRow>
              ))}
            </SparkTableBody>
          </SparkTable>
        </div>
      </div>
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default PartnerRequirement;

import "../scrap.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addIcon from "../../../assets/svgs/add.svg";
import { Outlet, useNavigate } from "react-router-dom";
import { ScrapsCategoryInfoDTO } from "../scraps-DTO";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import { getScrapCategories } from "../scraps-service";
import CardListScrapCategories from "./cardlist-scrap-categories/cardlist-scrap-categories";
import { useAuth } from "../../../contexts/auth-context";

const ScrapCategories: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;

  const [scrapCategories, setScrapCategories] = useState<
    ScrapsCategoryInfoDTO[]
  >([]);

  const fetchScrapCategories = async () => {
    try {
      const data = await getScrapCategories();
      setScrapCategories(data);
    } catch (error) {
      console.error("Error fetching scrap categories: ", error);
    }
  };

  useEffect(() => {
    fetchScrapCategories();
  }, []);

  const handleCloseModal = () => {
    fetchScrapCategories();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => {
              navigate("/scrap-categories-form");
            }}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>
          <ImportExportCsv
            exportApi="exportscrapbygeneratorscsv"
            importApi=""
            onClose={handleCloseModal}
          />
        </div>

        <CardListScrapCategories scrapCategories={scrapCategories} />
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapCategories;
import CardListScrapGenerator from "./components/cardlist-scrap-generator/cardlist-scrap-generator";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useEffect, useState } from "react";
import { getScrapGenerators } from "./scrap-generator-service";
import { ScrapGeneratorInfoDTO } from "./scrap-generators-DTO";
import FilterComponent from "../../components/FIlter/filter";
import { useAuth } from "../../contexts/auth-context";

const ScrapGenerators: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams();
  const [filteredScrapGenerators, setFilteredScrapGenerators] = useState<ScrapGeneratorInfoDTO[]>([]);
  const handleFilterClick = (filteredData: ScrapGeneratorInfoDTO[]) => {
    setFilteredScrapGenerators(filteredData);
  };

  const [scrapGenerators, setScrapGenerators] = useState<
    ScrapGeneratorInfoDTO[]
  >([]);

  const tabs = [
    { value: "1", label: t("label-list") },
    { value: "2", label: t("label-map") },
  ];
  const selectedTab = "1";

  const fetchScrapGenerators = async () => {
    try {
      let data: any;

      data = await getScrapGenerators();
      setScrapGenerators(data);
      setFilteredScrapGenerators(data);
    } catch (error) {
      console.error("Error fetching scrap Generators: ", error);
    }
  };


  const handleSortClick = () => {

    console.log('Sort click');
  };
  useEffect(() => {
    fetchScrapGenerators();
  }, []);

  const handleCloseModal = () => {
    fetchScrapGenerators();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => navigate("/scrap-generators-new")}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>

        <ImportExportCsv
          exportApi="exportscrapgeneratorscsv"
          importApi="importscrapgeneratorscsv"
          onClose={handleCloseModal}
        />

        <FilterComponent
          data={scrapGenerators}
          onFilterClick={handleFilterClick}
          onSortClick={handleSortClick}
        />
      </div>

      <SparkTabNavigation
        items={JSON.stringify(tabs)}
        selectedTab={selectedTab}
      ></SparkTabNavigation>
      <CardListScrapGenerator scrapGenerators={filteredScrapGenerators} />
    </div>
  );
  } else {
      navigate("/page-not-found")
      return <></>
    }
};

export default ScrapGenerators;

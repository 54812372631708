import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { getScrapCategories } from "../../pages/scraps/scraps-service";

const ScrapCategoryInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  onChange: (category: any) => void;
  initialValue?: any;
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  onChange,
  initialValue,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(
    initialValue || null
  );

  // Referência estável para onChange
  const handleChangeRef = React.useRef(onChange);
  handleChangeRef.current = onChange;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categories = await getScrapCategories();
        const options = categories.map((category) => ({
          value: category.id,
          label: category.name,
          data: category,
        }));
        setCategoryOptions(options);

        if (initialValue) {
          const initialCategory = options.find(
            (option) => option.label === initialValue.name
          );
          if (initialCategory) {
            setSelectedCategory(initialCategory.data);
            handleChangeRef.current(initialCategory.data);
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [initialValue]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.data) {
      setSelectedCategory(selectedOption.data);
      handleChangeRef.current(selectedOption.data);
    } else {
      setSelectedCategory(null);
      handleChangeRef.current(null);
    }
  };

  return (
    <div className="flex-column select-field">
      <label htmlFor="category">
        {label}
      </label>
      <div style={{ margin: "0.5rem 0rem"}}>
        <Select
          id="category"
          placeholder={placeholder}
          value={
            selectedCategory
              ? { value: selectedCategory.id, label: selectedCategory.name }
              : null
          }
          onChange={handleChange}
          options={categoryOptions}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default ScrapCategoryInputAutocomplete;

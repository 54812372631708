import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";

import list from "../../../assets/img/list-view.svg";
import card from "../../../assets/img/components.svg";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import EcommerceCard from "../../../components/EcommerceCard/EcommerceCard";
import Slider from "../../../components/Slider/Slider";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";

const ScrapAdminScrapsToDealView: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [slideData, setSliderData] = useState<string>("")
  const { id } = useParams<{ id: string }>();
  const data = { generatorId: id };
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-name")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
    `${t("label-region")}`,
  ];

  const colors: { [key: string]: string } = {
    "TO_DEAL": "#9e2896",
    "AWAITING_BID": "#9e2896",
    "IN_BID": "#007bc0",
    "BID_COMPLETED": "#00884a",
    "CANCELED": "#ed0007",
    "FINISHED": "#cd8d00",
    "CONTRACTED": "#00884a",
  }

  const fetchTableData = async () => {
    const q = query(
      collection(firestore, "ScrapByGenerator"),
      where("status", "==", "TO_DEAL")
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as unknown as any[];
    setTableData(items);
  };

  useEffect(() => {
    fetchTableData();
  }, [id]);

  const handleCloseModal = () => {
    fetchTableData();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row" >
            <ImportExportCsv
              exportApi="exportscrapbygeneratorscsv"
              importApi=""
              onClose={handleCloseModal}
            />
          </div>

          <Slider options={[
            { display: list, value: "list", viewType: "image" },
            { display: card, value: "card", viewType: "image" },
          ]} setState={setSliderData}></Slider>

        </div>

        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 && (
          slideData === "list" ?
            <>
              <div className="info-section">
            <SparkTable>
                <SparkTableHead>
                  <SparkTableRow>
                    <SparkTableCell className="icon-column"></SparkTableCell>
                    {tableHeader.map((header, index) => (
                      <SparkTableCell key={index}>
                        <strong>{header.toUpperCase()}</strong>
                      </SparkTableCell>
                    ))}
                  </SparkTableRow>
                </SparkTableHead>

                <SparkTableBody>
                  {tableData.map((rowItem: any, rowIndex: any) => (
                    <SparkTableRow
                      key={rowIndex}
                      onClick={() =>
                        navigate(`/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`)
                      }
                    >
                      <SparkTableCell className="icon-column">
                        <SparkIcon
                          className="icon-flag"
                          no-padding={true}
                          icName={
                            rowItem.scrapCategory.isHazardousMaterial
                              ? "danger-hazards"
                              : "less-minimize"
                          }
                        />
                      </SparkTableCell>
                      <SparkTableCell>{rowItem.scrapCategory.name}</SparkTableCell>
                      <SparkTableCell>{rowItem.originName}</SparkTableCell>
                      <SparkTableCell>
                        {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/{t(`periodENUM.${rowItem.period}`)}
                      </SparkTableCell>
                      <SparkTableCell>{rowItem.targetPrice}/{t(`unityENUM.${rowItem.unity}`)}</SparkTableCell>
                      <SparkTableCell>
                        {rowItem.operationAddress.city} - {rowItem.operationAddress.countryName}
                      </SparkTableCell>
                    </SparkTableRow>
                  ))}
                </SparkTableBody>
              </SparkTable>
</div>
              <div style={{ padding: "1rem 0rem" }} className="flex-row">
                <div style={{ padding: "1rem" }} className="flex-row align-center">
                  <SparkIcon no-padding={true} icName="danger-hazards" />
                  <p>- {t("label-hazardous-material")}</p>
                </div>
                <div style={{ padding: "1rem" }} className="flex-row align-center  ">
                  <SparkIcon no-padding={true} icName="less-minimize" />
                  <p>- {t("label-non-hazardous-material")}</p>
                </div>
              </div></>
            :
            <div className="eccomerce-card-wrapper">
              {
                tableData.map((rowItem: any, rowIndex: any) =>
                  <EcommerceCard
                    onClick={() => navigate(`/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`)}
                    buttonAction={() => { }}
                    buttontext="Vizualizar"
                    imageUrl={rowItem.images.length > 0 ? rowItem.images : rowItem.scrapCategory.image || "Image not found!"}
                    price={rowItem.targetPrice}
                    unitPrice={t(`unityENUM.${rowItem.unity}`)}
                    key={rowIndex}
                    customBottom={
                      rowItem.scrapCategory.isHazardousMaterial ?
                        <div className="flex-row align-center icon-container">
                          <SparkIcon no-padding={true} icName="danger-hazards" />
                          <p className="hidden-label">{t("label-hazardous-material")}</p>
                          <div style={{ backgroundColor: colors[rowItem.status], padding: 10, color: "white" }}>
                            {t(`statusEnum.${rowItem.status}`)}
                          </div>
                        </div>
                        :
                        <div className="flex-row align-center icon-container">
                          <SparkIcon no-padding={true} icName="less-minimize" />
                          <p className="hidden-label">{t("label-non-hazardous-material")}</p>
                          <div style={{ backgroundColor: colors[rowItem.status], padding: 10, color: "white" }}>
                            {t(`statusEnum.${rowItem.status}`)}
                          </div>
                        </div>
                    }
                  >
                    <div>
                      <h3>{rowItem.scrapCategory.description}</h3>
                      <p style={{ marginTop: 7 }}>{rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/{t(`periodENUM.${rowItem.period}`)}</p>
                      <p style={{ marginTop: 7 }}>{t(`withdrawal`)} {t(`periodENUM.${rowItem.pickUpFrequency}`)}</p>
                      <p style={{ marginTop: 7 }}>{rowItem.operationAddress.city} - {rowItem.operationAddress.countryName}</p>
                      <strong><p style={{ marginTop: 7 }}>{rowItem.originName}</p></strong>
                    </div>
                  </EcommerceCard>
                )
              }
            </div>
        )}
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapAdminScrapsToDealView;

import CardListScrapDealer from "./components/cardlist-scrap-dealer/cardlist-scrap-dealer";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useEffect, useState } from "react";
import {
  getBranchsScrapDealersById,
  getScrapDealers,
} from "./scrap-dealers-service";
import { ScrapDealerInfoDTO } from "./scrap-dealer-DTO";
import { useAuth } from "../../contexts/auth-context";

const ScrapDealers: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [scrapDealers, setScrapDealers] = useState<ScrapDealerInfoDTO[]>([]);
  const { id } = useParams();

  const tabs = [
    { value: "1", label: t("label-list") },
    { value: "2", label: t("label-map") },
  ];
  const selectedTab = "1";

  const fetchScrapDealers = async () => {
    try {
      let data: any;

      data = await getScrapDealers();

      setScrapDealers(data);
    } catch (error) {
      console.error("Error fetching scrap dealers: ", error);
    }
  };

  useEffect(() => {
    fetchScrapDealers();
  }, []);

  const handleCloseModal = () => {
    fetchScrapDealers();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => navigate("/scrap-dealers-new")}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>

          <ImportExportCsv
            exportApi="exportscrapdealerscsv"
            importApi="importscrapdealerscsv"
            onClose={handleCloseModal}
          />
        </div>

        <SparkTabNavigation
          items={JSON.stringify(tabs)}
          selectedTab={selectedTab}
        ></SparkTabNavigation>
        <CardListScrapDealer scrapDealers={scrapDealers} />
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapDealers;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import uploadIcon from "../../assets/svgs/upload.svg";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { app } from "../../utils/firebase-config";
import downloadIcon from "../../assets/svgs/download.svg";
import UploadCsvModal from "./UploadCsvModal/UploadCsvModal";
import { callFunction } from "../../services/persistence";

interface ButtonProps {
  importApi?: string;
  exportApi?: string;
  data?: any;
  onClose: () => void;
}

const ImportExportCsv = ({ onClose, importApi, exportApi, data }: ButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    onClose()
    setIsModalOpen(false);
  };

  const exportCsv = (apiData: string, data?: any) => {

    document.body.style.cursor = 'wait';
    callFunction(apiData, data).then(result => {
      const nomeArquivo = result.data as string;
      const storage = getStorage(app);
      const storageRef = ref(storage, nomeArquivo);
      getDownloadURL(storageRef).then((url) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = nomeArquivo;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }).finally(() => {
        document.body.style.cursor = 'auto';
      })
    });
  }

  const { t } = useTranslation();

  return (
    <div className="flex-row">
      {
        importApi && <button type="button" title={t("label-upload-csv")}
          onClick={handleOpenModal}
          className="neutral-white white-with-icon button ml-10"
        >
          <img src={uploadIcon} alt={t("label-upload-csv")} />
        </button>
      }

      {exportApi && <button type="button" className="neutral-white white-with-icon button ml-10" onClick={() => exportCsv(exportApi, data)}>
        <img src={downloadIcon} alt={t("label-dowload-csv")} />
      </button>}

      {isModalOpen && importApi && <UploadCsvModal onClose={handleCloseModal} data={data} apiData={importApi} templateUrl={`https://firebasestorage.googleapis.com/v0/b/rbprj-100132.appspot.com/o/templates%2F${importApi}.csv?alt=media`} />}
    </div>
  );
};

export default ImportExportCsv;

import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './CollapsibleSection.css';
import { SparkIcon } from '@bosch-web-dds/spark-ui-react';

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const nodeRef = useRef<HTMLDivElement>(null);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible-section">
      <div className="header" onClick={toggleSection}>
        <h2>{title}</h2>
        <span className="icon"></span>
        <SparkIcon icName={isOpen ? 'up' : 'down'} noPadding></SparkIcon>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="collapse"
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div ref={nodeRef} className="collapsible-section-content">{children}</div>
      </CSSTransition>
    </div>
  );
};

export default CollapsibleSection;

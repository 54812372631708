import React, { createContext, useState, useContext, ReactNode } from 'react';
import Alert, { AlertProps } from '../components/Alert/Alert';

interface AlertContextProps {
  setAlert: (props: AlertProps) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alertProps, setAlertProps] = useState<AlertProps | null>(null);

  const setAlert = (props: AlertProps) => {
    setAlertProps(props);
    setTimeout(() => {
      setAlertProps(null);
    }, 5000);
  };

  return (
    <AlertContext.Provider value={{ setAlert }}>
      {children}
      {alertProps && <Alert text={alertProps.text} type={alertProps.type} />}
    </AlertContext.Provider>
  );
};

export const useAlert = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

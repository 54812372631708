import React from 'react';
import CardScrapGenerator from './card-scrap-generator/card-scrap-generator';
import NoData from '../../../../components/NoData/NoData';
import { ScrapGeneratorInfoDTO } from '../../scrap-generators-DTO';

interface CardListProps {
  scrapGenerators: ScrapGeneratorInfoDTO[];
}

const CardListScrapGenerator: React.FC<CardListProps> = ({ scrapGenerators }) => {
  return (
    <div className="card-list">
      {scrapGenerators.length === 0 ? (
        <NoData />
      ) : (
        scrapGenerators.map((item, index) => (
          <CardScrapGenerator key={index} item={item} />
        ))
      )}
    </div>
  );
};

export default CardListScrapGenerator;



export function formatTimestampDateAndTime(timestamp: { seconds: number, nanoseconds: number }): string {
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

export function addDaysAndTransformDateStringToDate(dateString: string, daysToAdd: number): Date {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() + daysToAdd);
  return date;
}

export function addDaysToToday(daysToAdd: number): string {
  const today = new Date();
  const futureDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
  const year = futureDate.getFullYear();
  const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
  const day = futureDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

import React, { useEffect, useState } from "react";
import Select, { components, MultiValue, ActionMeta } from "react-select";
import fetchScrapDealers from "./ScrapDealersIInputAutocompleteService";
import { useTranslation } from "react-i18next";

const ScrapDealerInputAutocomplete: React.FC<{
  placeholder: string;
  onChange: (scrapDealers: any[]) => void;
  isDisabled?: boolean;
  defaultValue?: any[];
}> = ({ placeholder, onChange, isDisabled, defaultValue }) => {
  const [selectedScrapDealers, setSelectedScrapDealers] = useState<any[]>([]);
  const [scrapDealerOptions, setScrapDealerOptions] = useState<any[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const scrapDealers = await fetchScrapDealers();
        const options = scrapDealers.map((scrapDealer) => ({
          value: scrapDealer.id,
          label: scrapDealer.legalName,
          data: scrapDealer,
        }));
        setScrapDealerOptions(options);

        if (defaultValue) {
          const defaultOptions = defaultValue.map((dealer) => ({
            value: dealer.id,
            label: dealer.legalName,
            data: dealer,
          }));
          setSelectedScrapDealers(defaultOptions);
        }
      } catch (error) {
        console.error("Error fetching scrap dealers:", error);
      }
    };
    fetchData();
  }, [defaultValue]);

  const handleChange = (
    selectedOptions: MultiValue<{ value: any; label: any; data: any }>
  ) => {
    const mutableSelectedOptions = Array.isArray(selectedOptions) ? [...selectedOptions] : [];
    const selectedData = mutableSelectedOptions.map((option) => option.data);

    setSelectedScrapDealers(mutableSelectedOptions);
    onChange(selectedData);
  };

  const customOption = (props: any) => (
    <components.Option {...props}>
      <div className="flex-row">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </div>
    </components.Option>
  );

  return (
    <div className="flex-column select-field">
      <Select
        id="scrapDealer"
        placeholder={placeholder}
        value={selectedScrapDealers}
        onChange={handleChange}
        options={scrapDealerOptions}
        isDisabled={isDisabled}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option: customOption }}
      />
    </div>
  );
};

export default ScrapDealerInputAutocomplete;
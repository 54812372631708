import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { EmailsWarningInfoDTO } from "../models/emails-warning-DTO";
import EmailsWarningForm from "../emails-warning-form/emails-warning-form";

interface Props {
  item: EmailsWarningInfoDTO;
  onClose: () => void;
}

const CardEmailsWarning: React.FC<Props> = ({ onClose, item }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose()
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal-background">
          <EmailsWarningForm userId={item.id} onClose={handleCloseModal} />
        </div>
      )}
      <div className="flex-row card-dealer" onClick={handleOpenModal}>

          <div className="flex-column infos">
            <h3 style={{margin:0}}>{item.name || "-"}</h3>
              <div className="custom-line-hr"/>
            <p>
              {t("label-email")}: <strong>{item.email || "-"}</strong>
            </p>

        </div>
      </div>
    </div>
  );
};

export default CardEmailsWarning;

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../utils/firebase-config";
import { COLLECTIONS } from "../assets/enums/firebase-colections";

export interface AddressEntityDTO {
  address: string;
  addressNumber: string;
  city: string;
  country: string;
  countryName: string;
  postalCode: string;
  postalCodeLabel: string;
  state: string;
}

export interface CountryConfigDTO {
  id:string;
  country: string[];
  currency: string;
  currencyFormatting: string;
  currencyLabel: string;
  currencyName: string;
  locale: string;
  nationalRegister: string;
  nationalRegisterFormatting: string;
  paymentGateway: string;
  postalCode: string;
  postalCodeFormatting: string;
}

export async function getAdrressByEntityId(id: string) {
  const docRef = doc(firestore, COLLECTIONS.ADDRESS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as AddressEntityDTO;
}

export async function getCountryConfigById(id: string) {
    const docRef = doc(firestore, COLLECTIONS.COUNTRY_CONFIG, id);
    const docSnap = await getDoc(docRef);
  
    return docSnap.data() as CountryConfigDTO;
  }

import { ScrapDealerInfoDTO } from "../../scrap-dealer-DTO";
import CardScrapDealer from "./card-scrap-dealer/card-scrap-dealer";
import NoData from "../../../../components/NoData/NoData";

interface cardlistProps {
  scrapDealers: ScrapDealerInfoDTO[]
}

const CardListScrapDealer = ({scrapDealers}: cardlistProps) => {
  return (
    <div className="card-list">
      {scrapDealers.map((item, index) => (
        <CardScrapDealer key={index} item={item} />
      ))}
      {scrapDealers.length === 0 && <NoData />}
    </div>
  );
};

export default CardListScrapDealer;

import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { ScrapDealerDTO } from "./models/ScrapDealers-DTO";

const fetchScrapDealers = async (): Promise<ScrapDealerDTO[]> => {
  const db = getFirestore();
  const scrapDealerCollectionRef = collection(db, COLLECTIONS.SCRAP_DEALERS);
  const scrapDealerQuery = query(
    scrapDealerCollectionRef,
  );
  const scrapDealerSnapshot = await getDocs(scrapDealerQuery);

  const scrapDealerList: ScrapDealerDTO[] = [];
  scrapDealerSnapshot.forEach((doc) => {
    const scrapDealerData = doc.data();
    const scrapDealer: ScrapDealerDTO = {
      id: doc.id,
      legalName: scrapDealerData.legalName || "",
      tradingName: scrapDealerData.tradingName || "",
    };
    scrapDealerList.push(scrapDealer);
  });

  return scrapDealerList;
};

export default fetchScrapDealers;

import { useTranslation, Trans } from "react-i18next";
const CardDetalsMobile: any = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="mobile">
      <div className="container-highlights color-white">
        <div className="container-industry">
          <div>
            <span className="text-tons">
              {t("landing-page.value-industry")}
            </span>
          </div>
          <div className="detals-one">
            <span className="text-percentage-hundred-detals">
              {t("landing-page.plants-industry")}
            </span>
            <span className="text-percentage-hundred-detals">
              {t("landing-page.plants-industrys")}
            </span>
          </div>
        </div>

        <div className="container-cash">
          <div>
            <span className="text-tons">
              {t("landing-page.value-projects")}
            </span>
          </div>
          <div className="detals-one">
            <span className="text-percentage-hundred-detals">
              {t("landing-page.projects")}
            </span>
          </div>
        </div>

        <div className="container-industry">
          <div>
            <span className="text-tons ">
              {t("landing-page.percentage-hundred")}
            </span>
          </div>
          <div className="detals-one">
            <span className="text-percentage-hundred-detals">
              {t("landing-page.compliace")}
            </span>
          </div>
        </div>

        <div className="container-cash">
          <div>
            <span className="text-tons">{t("landing-page.value-ten")}</span>
          </div>
          <div className="detals-ones">
            <span className="text-percentage-hundred-detals">
              <Trans
                i18nKey={t("landing-page.invoicings")}
                components={{ bold: <strong /> }}
              />
            </span>
          </div>
        </div>

        <div className="container-industry">
          <div>
            <span className="text-tons">
              {t("landing-page.percentage-one")}
            </span>
          </div>

          <div className="detals-ones">
            <span className="text-percentage-hundred-detals">
              <Trans
                i18nKey={t("landing-page.percentage-one-tablet")}
                components={{ bold: <strong /> }}
              />
            </span>
          </div>
        </div>

        <div className="container-cash">
          <div>
            <span className="text-tons">
              {t("landing-page.percentage-two")}
            </span>
          </div>

          <div className="detals-ones">
            <span className="text-percentage-hundred-detals">
              <Trans
                i18nKey={t("landing-page.percentage-two-tablet")}
                components={{ bold: <strong /> }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetalsMobile;

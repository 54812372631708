import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";

import {
  CountryConfigInfoDTO,
  CountryConfigDTO,
  ObservationsDTO,
} from "./country-config-DTO";
import { saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";


// add new
export async function addNew(data: CountryConfigDTO) {
  data.countryConfigInfoDTO.country = data.countryConfigInfoDTO.countries.replace(", ", ",").split(",");
  if (data.id == undefined) {
    saveNewAsync(COLLECTIONS.COUNTRY_CONFIG, data.countryConfigInfoDTO).then(
      (docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      }
    );
  } else {
    update(COLLECTIONS.COUNTRY_CONFIG, data.countryConfigInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
  }
}

export async function getCountryConfigs(): Promise<CountryConfigInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.COUNTRY_CONFIG);

  const q = query(collectionRef, orderBy("country"));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as CountryConfigInfoDTO[];
  return items;
}

export const getById = async (
  id: string
): Promise<CountryConfigDTO | null> => {
  const countryConfigInfoDTO = await getInfoById(id);
  const observations = await getObservation(id);

  return {
    id,
    countryConfigInfoDTO,
    observations,
  };
};

export async function getInfoById(id: string): Promise<CountryConfigInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.COUNTRY_CONFIG, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as CountryConfigInfoDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

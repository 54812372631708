import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import {
  BanksInfoDTO,
  BanksDTO,
  ObservationsDTO,
} from "./banks-DTO";
import { saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { ScrapDealerInfoDTO } from "../../settings-DTO";
import { firestore } from "../../../../utils/firebase-config";


// add new
export async function addNew(data: BanksDTO, id: string) {
  if (data.id == undefined) {
    data.banksInfoDTO.originId = id;
    saveNewAsync(COLLECTIONS.BANKS, data.banksInfoDTO).then(
      (docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      }
    );
  } else {
    update(COLLECTIONS.BANKS, data.banksInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
  }
}

async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

export async function getBanksByOriginId(
  id: string
): Promise<BanksInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.BANKS);

  const q = query(collectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as BanksInfoDTO[];
  return items;
}

export const getById = async (
  id: string
): Promise<BanksDTO | null> => {
  const banksInfoDTO = await getInfoById(id);
  const observations = await getObservation(id);

  return {
    id,
    banksInfoDTO,
    observations,
  };
};

export async function getInfoById(id: string): Promise<BanksInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.BANKS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as BanksInfoDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

export const COLLECTIONS = {
  USERS: "Users",
  EMAIL_WARNING: "EmailsWarning",
  SCRAP_DEALERS: "ScrapDealers",
  SCRAP_GENERATORS: "ScrapGenerators",
  COUNTRY_CONFIG: "CountryConfig",
  BANK_INFO: "BankInfo",
  ADDRESS: "Address",
  CONTACTS: "Contacts",
  OBSERVATIONS: "Observations",
  SCRAP_CATEGORIES: "ScrapCategories",
  AB_SENTENCES: "ABSentences",
  CREDIT_ANALYSIS: "CreditAnalysis",
  EXPIRATION_DOCUMENTS: "ExpirationDocuments",
  SCRAPS_GENERATOR_FORM: "ScrapByGeneratorTeste",
  SCRAP_BY_GENERATOR: "ScrapByGenerator",
  SCRAP_BY_GENERATOR_NEGOTIATION: "ScrapByGeneratorNegotiation",
  SCRAP_BY_GENERATOR_BID: "ScrapByGeneratorBid",
  SCRAP_BY_GENERATOR_CONTRACT: "ScrapByGeneratorContract",
  SCRAP_BY_GENERATOR_INVITED_TABLE: "ScrapByGeneratorInvitedTable",
  CHANGELOG: "Changelog",
  PARTNER_REQUIREMENTS: "PartnerRequirements",
  PARTNER_REQUIREMENTS_DEALER: "PartnerRequirementsDealer",
  PARTNER_REQUIREMENTS_DEALER_TOTAL: "PartnerRequirementFullfilled",
  CONTAINERS_TYPE: "ContainersType",
  BANKS: "Banks",
  IN_BILLING_CREDIT :"InBillingCredit",
};

export const formatPrice = (price: number): string => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(price);
};

export function maxLengthForNumbers(event: any, maxLength: number) {
  if (event.target.value.length > maxLength) {
    event.target.value = event.target.value.slice(0, maxLength);
  }
}

export function phoneMask(phoneNumber: string) {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  let maskedNumber = "";
  if (cleaned.length === 11) {
    maskedNumber = cleaned.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else {
    maskedNumber = cleaned.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }
  return maskedNumber;
}


export function applyDecimalMask(input: string): string {

  const numericValue = input.replace(/[^0-9]/g, '');

  if (!numericValue) return '';

  const [integerPart, decimalPart] = numericValue.split('.');

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (decimalPart) {
      return `${formattedIntegerPart},${decimalPart}`;
  } else {
      return formattedIntegerPart;
  }
}



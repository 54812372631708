import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ContactInfoDTO } from "../contacts-DTO";
import ContactForm from "../contact-form/contact-form";

interface Props {
  item: ContactInfoDTO;
  onClose: () => void;
}

const CardContact = ({ onClose, item }: Props) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal-background">
          <ContactForm contactId={item.id} onClose={handleCloseModal} />
        </div>
      )}
      <div className="flex-row card-dealer" onClick={handleOpenModal}>
        <div className="flex-column infos">
          <h3>{item.name || "-"}</h3>
          <div className="custom-line-hr" />
          <p>
            {t("label-email")}: <strong>{item.email || "-"}</strong>
          </p>
          <p>
            {t("label-phone")}: <strong>{item.phone || "-"}</strong>
          </p>
          <p>
            {t("label-type")}: <strong>{item.type || "-"}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardContact;

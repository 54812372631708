import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrapDealerDTO } from "../../../../scrap-dealer-DTO";
import { getScrapDealerById } from "../../../../scrap-dealers-service";
import { useAuth } from "../../../../../../contexts/auth-context";

const ScrapDealerDetailsRegister: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [scrapDealer, setScrapDealer] = useState<ScrapDealerDTO | null>(null);

  useEffect(() => {
    const fetchScrapDealer = async () => {
      if (!id) return;
      try {
        const data = await getScrapDealerById(id);
        if (data) {
          setScrapDealer(data);
        } else {
          console.error("Data is undefined");
        }
      } catch (error) {
        console.error("Error fetching scrap dealer:", error);
      }
    };

    fetchScrapDealer();
  }, [id]);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="register-tab">
        <div className="flex-row edit-section">
          <a onClick={() => navigate(`/scrap-dealers-edit/${scrapDealer?.id}`)}>
            {t("label-edit")}
          </a>
        </div>

        {scrapDealer?.scrapDealerInfo.headquarterId && (
          <div className="info-section">
            <div className="flex-row title">
              <h3>{t("label-headquarter")}</h3>
              <a
                onClick={() =>
                  navigate(
                    `/scrap-dealers-details/${scrapDealer?.scrapDealerInfo.headquarterId}/register`
                  )
                }
              >
                {t("label-view")}
              </a>
            </div>
            <div className="flex-column">
              <label>{t("label-headquarter-name")}</label>
              <input
                value={scrapDealer?.scrapDealerInfo.headquarterName || "-"}
                disabled={true}
              />
            </div>
          </div>
        )}

        <div className="info-section">
          <h3>{t("label-business-information")}</h3>
          <div className="flex-column">
            <label>{t("label-legal-name")}</label>
            <input
              value={scrapDealer?.scrapDealerInfo.legalName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>{t("label-trading-name")}</label>
            <input
              value={scrapDealer?.scrapDealerInfo.tradingName || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>
              {scrapDealer?.scrapDealerInfo.nationalRegisterLabel ||
                t("label-national-register")}
            </label>
            <input
              value={scrapDealer?.scrapDealerInfo.nationalRegister || "-"}
              disabled={true}
            />
          </div>
          <div className="flex-column">
            <label>{t("label-next-contract-due-date")}</label>
            <input
              value={scrapDealer?.scrapDealerInfo.nextContractDueDate || "-"}
              disabled={true}
            />
          </div>
        </div>
        <div className="info-section">
          <h3>{t("label-location")}</h3>
          <div className="flex-column">
            <label>
              {scrapDealer?.addressInfo.postalCodeLabel || t("label-postal-code")}
            </label>
            <input
              value={scrapDealer?.addressInfo.postalCode || "-"}
              disabled={true}
            />
          </div>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-address")}</label>
              <input
                value={scrapDealer?.addressInfo.address || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-number")}</label>
              <input
                value={scrapDealer?.addressInfo.addressNumber || "-"}
                disabled={true}
              />
            </div>
          </div>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-city")}</label>
              <input
                value={scrapDealer?.addressInfo.city || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-state")}</label>
              <input
                value={scrapDealer?.addressInfo.state || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="info-section">
          <h3>{t("label-contact")}</h3>
          <div className="flex-column">
            <label>{t("label-contact-name")}</label>
            <input value={scrapDealer?.contactInfo.name || "-"} disabled={true} />
          </div>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-email")}</label>
              <input
                value={scrapDealer?.contactInfo.email || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-phone")}</label>
              <input
                value={scrapDealer?.contactInfo.phone || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="info-section">
          <h3>{t("label-bank-details")}</h3>
          <div className="double-fields">
            <div className="flex-column">
              <label>{t("label-bank")}</label>
              <input
                value={scrapDealer?.bankAccountInfo.bank || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-account-type")}</label>
              <input
                value={
                  t(
                    `accountTypesENUM.${scrapDealer?.bankAccountInfo.accountType}_ACCOUNT`
                  ) || "-"
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="triple-fields">
            <div className="flex-column">
              <label>{t("label-account-number")}</label>
              <input
                value={scrapDealer?.bankAccountInfo.accountNumber || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-digit")}</label>
              <input
                value={scrapDealer?.bankAccountInfo.digit || "-"}
                disabled={true}
              />
            </div>
            <div className="flex-column">
              <label>{t("label-agency")}</label>
              <input
                value={scrapDealer?.bankAccountInfo.agency || "-"}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="info-section">
          <h3>{t("label-general-observations")}</h3>
          <div>
            <textarea
              disabled
              value={scrapDealer?.observations.observations}
            ></textarea>
          </div>
        </div>
        <div className="flex-row buttons-section">
          <button
            onClick={() => {
              navigate("/scrap-dealers");
            }}
            className="neutral-white"
          >
            {t("label-back")}
          </button>
        </div>
        <br />
        <br />
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapDealerDetailsRegister;

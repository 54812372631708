import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { UserInfoDTO } from "../models/users-DTO";
import { getUsersById } from "../users-service";
import CardUser from "../card-users/card-user";
import NoData from "../../../../../components/NoData/NoData";

interface cardlistProps {
  users: UserInfoDTO[];
  onClose: () => void;
}

const CardListUser = ({onClose, users}: cardlistProps) => {
  return (
    <div className="card-list">
      {users.map((item, index) => (
        <CardUser onClose={onClose} key={index} item={item} />
      ))}
      {users.length === 0 && <NoData />}
    </div>
  );
};

export default CardListUser;

import React from "react";
import "../../../scrap.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScrapsCategoryInfoDTO } from "../../../scraps-DTO";
import genericImage from "../../../../../assets/svgs/material-list.svg"

interface Props {
  item: ScrapsCategoryInfoDTO;
}

const CardScrapCategory: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="flex-row card-dealer"
      onClick={() => {
        navigate(`/scrap-categories-details/${item.id}/edit`);
      }}
    >
      <div className="flex-row" style={{width:"100%"}}>
        <img src={item.image ? item.image : genericImage} alt="material image" />
        <div className="flex-column infos" style={{width:"100%"}}>
          <h3 style={{margin:0}}>{item.name || "-"}</h3>
          <div className="custom-line-hr"/>
          <p>
            {t("label-description")}: <strong>{item.description || "-"}</strong>
          </p>
          <p>
            {t("label-composition")}:{" "}
            <strong>{item.composition || "-"}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardScrapCategory;

import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { HeadquarterDTO } from "./models/headquarter-DTO";

const fetchHeadquarters = async (headQuarterCollection:any): Promise<HeadquarterDTO[]> => {
  const db = getFirestore();
  const headquarterCollection = collection(db, headQuarterCollection);
  const headquarterQuery = query(
    headquarterCollection,
    where("isHeadquarter", "==", true)
  );
  const headquarterSnapshot = await getDocs(headquarterQuery);

  const headquarterList: HeadquarterDTO[] = [];
  headquarterSnapshot.forEach((doc) => {
    const headquarterData = doc.data();
    const headquarter: HeadquarterDTO = {
      id: doc.id,
      legalName: headquarterData.legalName || "",
      tradingName: headquarterData.tradingName || "",
    };
    headquarterList.push(headquarter);
  });

  return headquarterList;
};

export default fetchHeadquarters;

import "./LanguagePicker.css"
import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import globe from "../../assets/img/globe.svg"

const LanguagePicker: React.FC = () => {
    const { i18n, t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setIsOpen(false);
    };

    return (
        <div className="language-selector">
            <div onClick={() => setIsOpen(!isOpen)} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <img src={globe} height={24} width={24}></img>
            </div>
            {isOpen && (
                <div className="language-dropdown">
                    <button className="language-button" onClick={() => changeLanguage(navigator.language.split('-')[0])}>{t("LanguagePicker.default")}</button>
                    <button className="language-button" onClick={() => changeLanguage('pt')}>Português</button>
                    <button className="language-button" onClick={() => changeLanguage('en')}>English</button>
                    <div style={{height: 12}}></div>
                </div>
            )}
        </div>
    );
};

export default LanguagePicker;

import { Trans, useTranslation } from "react-i18next";

interface Props {
  title: string;
  description: string;
}

const CardTexts: React.FC<Props> = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <div className="title-description">
      <h3>{title} </h3>
      <span className="titles">
        <Trans i18nKey={description} components={{ bold: <strong /> }} />
      </span>
    </div>
  );
};

export default CardTexts;

import React, { useState } from 'react';
import Modal from '../../components/Modal/modal';
import { ScrapGeneratorInfoDTO } from '../..//pages/scrap-generators/scrap-generators-DTO';
import { RiSearch2Line, RiFilterLine, RiArrowUpDownLine } from 'react-icons/ri';
import './filter.css';
import '../../components/Modal/modal.css';

interface FilterComponentProps {
  data: ScrapGeneratorInfoDTO[];
  onFilterClick: (filteredData: ScrapGeneratorInfoDTO[]) => void;
  onSortClick: () => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ data, onFilterClick, onSortClick }) => {
  const [filterValue, setFilterValue] = useState('');
  const [isAscending, setIsAscending] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setFilterValue(value);

    const filteredData = data.filter(item =>
      item.legalName.toLowerCase().includes(value)
    );

    onFilterClick(filteredData);
  };

  const handleSort = () => {
    // Implement sorting logic here
    onSortClick();
    setShowFilterModal(true);
  };

  const handleToggleSortOrder = () => {
    setIsAscending(!isAscending);
    // Implement logic to sort data based on the order
    setShowSortModal(true);
  };

  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const closeSortModal = () => {
    setShowSortModal(false);
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(event.target.value);
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOptions(prevSelectedOptions => 
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter(option => option !== value)
        : [...prevSelectedOptions, value]
    );
  };

  return (
    <div className="filter-component">
      <div className="filter-container">
        <div className="search-container">
          <input
            type="text"
            value={filterValue}
            onChange={handleFilterChange}
            placeholder="Buscar"
            className="filter-input"
          />
          <div className="search-icon">
            <RiSearch2Line />
          </div>
        </div>
        
        {/* <button onClick={handleSort} className="filter-button">
          <RiFilterLine className="filter-icon" />
          <span>Filtrar</span>
        </button>
  
        <button onClick={handleToggleSortOrder} className="filter-button">
          <RiArrowUpDownLine className="filter-icon" />
        </button> */}
      </div>

      <Modal
        isOpen={showFilterModal}
        onClose={closeFilterModal}
      >
       
        <div className="select-group">
          <label>País:</label>
          <div className="select-container">
            <select value={selectedCountry} onChange={handleCountryChange}>
              <option value="">Selecione um país</option>
              <option value="Brasil">Brasil</option>
              <option value="Estados Unidos">Estados Unidos</option>
              <option value="Canadá">Canadá</option>
              {/* Add more countries as needed */}
            </select>
          </div>
        </div>

        <div className="select-group">
          <label>Estado:</label>
          <div className="select-container">
            <select value={selectedState} onChange={handleStateChange}>
              <option value="">Selecione um estado</option>
              <option value="São Paulo">São Paulo</option>
              <option value="Rio de Janeiro">Rio de Janeiro</option>
              <option value="Minas Gerais">Minas Gerais</option>
              {/* Add more states as needed */}
            </select>
          </div>
        </div>

        <div className="radio-group">
          <div>
            <input
              type="radio"
              value="Filial"
              checked={selectedType === 'Filial'}
              onChange={handleTypeChange}
            />
            <label>Filial</label>
          </div>
          <div>
            <input
              type="radio"
              value="Matriz"
              checked={selectedType === 'Matriz'}
              onChange={handleTypeChange}
            />
            <label>Matriz</label>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showSortModal}
        onClose={closeSortModal}
      >
       
        <div className="options-container">
          <label className="option-label">
            <input
              type="checkbox"
              value="Option1"
              checked={selectedOptions.includes('Option1')}
              onChange={handleOptionChange}
            />
            Opção 1
          </label>
          <label className="option-label">
            <input
              type="checkbox"
              value="Option2"
              checked={selectedOptions.includes('Option2')}
              onChange={handleOptionChange}
            />
            Opção 2
          </label>
          <label className="option-label">
            <input
              type="checkbox"
              value="Option3"
              checked={selectedOptions.includes('Option3')}
              onChange={handleOptionChange}
            />
            Opção 3
          </label>
        </div>
      </Modal>
    </div>
  );
};

export default FilterComponent;

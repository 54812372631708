import { useTranslation } from "react-i18next";
import icon from "../../../../assets/img/arrow.svg";

const ClickRepresentativeMobile: any = ({}) => {
  const { t } = useTranslation();
  const subject = t("mail.subject");
  const body = t("mail.body");
  
  return (
    <div className="container-representative-Mobile">
      <div className="title-representative font-bold">
        <span className="font-weight">
          {t("landing-page.speak-representative")}
        </span>
      </div>
      <div className="container-representative marginleft">
        <div><a className="title-mobile" href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>{t("landing-page.click")}</a></div>
        <div className="icon"><img width={25} height={25} src={icon} alt="ilustration - open email"/></div>
      </div>
    </div>
  );
};

export default ClickRepresentativeMobile;

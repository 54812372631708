export function calculateFromCurrency(
  currencyString: string,
  amountToCalculate: number,
  currency: string,
  locale: string,
  currencyFormatting: string
): string {
  const numberString = currencyString.replace(/[^\d,.-]/g, "");
  let number;
  if (currencyFormatting == ",") {
    number = numberString.replace(".", "");
    number = number.replace(",", ".");
    number = parseFloat(number.replace(",", "."));
  } else {
    number = parseFloat(numberString.replace(",", ""));
  }
  const resultNumber = number + amountToCalculate; 
  const result = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(resultNumber); 
  return result;
}

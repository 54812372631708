import React, { useEffect, useState } from "react";
import { CreditAnalysisInfoDTO } from "../credit-analysis-DTO";
import CardContact from "./card-credit-analysis/card-credit-analysis";
import NoData from "../../../../../../../components/NoData/NoData";

interface cardlistProps {
  creditAnalysis: CreditAnalysisInfoDTO[];
  onClose: () => void;
}

const CreditAnalysisCardList = ({ onClose, creditAnalysis }: cardlistProps) => {
  return (
    <div className="card-list">
      <br></br>
      {creditAnalysis.map((item, index) => (
        <CardContact onClose={onClose} key={index} item={item} />
      ))}
      {creditAnalysis.length === 0 && <NoData />}
    </div>
  );
};

export default CreditAnalysisCardList;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import fetchHeadquarters from "./HeadquarterInputAutocompleteService";
import { useTranslation } from "react-i18next";

const HeadquarterInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  register: any;
  errors: any;
  onChange: (headquarter: any) => void;
  initialValue?: any;
  headQuarterCollection: string;
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  register,
  errors,
  onChange,
  initialValue,
  isDisabled,
  headQuarterCollection
}) => {
  const [selectedHeadquarter, setSelectedHeadquarter] = useState<any>(
    initialValue || null
  );
  const [headquarterOptions, setHeadquarterOptions] = useState<any[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headquarters = await fetchHeadquarters(headQuarterCollection);
        const options = headquarters.map((headquarter) => ({
          value: headquarter.id,
          label: headquarter.legalName,
          data: headquarter,
        }));
        setHeadquarterOptions(options);

        if (initialValue) {
          const initialHeadquarter = options.find(
            (option) => option.data.tradingName === initialValue.headquarterName
          );
          if (initialHeadquarter) {
            setSelectedHeadquarter(initialHeadquarter.data);
            onChange(initialHeadquarter.data);
          }
        }
      } catch (error) {
        console.error("Error fetching headquarters:", error);
      }
    };
    fetchData();
  }, [initialValue]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.data) {
      setSelectedHeadquarter(selectedOption.data);
      onChange(selectedOption.data);
    } else {
      setSelectedHeadquarter(null);
      onChange(null);
    }
  };

  return (
    <div className="flex-column select-field">
      <label htmlFor="headquarter">{label}</label>
      <Select
        id="headquarter"
        placeholder={placeholder}
        value={
          selectedHeadquarter
            ? {
                value: selectedHeadquarter.id,
                label: selectedHeadquarter.legalName,
              }
            : null
        }
        onChange={handleChange}
        options={headquarterOptions}
        isDisabled={isDisabled}
      />
      {errors.headquarter && <span>{t("error-input-required")}</span>}
    </div>
  );
};

export default HeadquarterInputAutocomplete;

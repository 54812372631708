import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import CardListUser from "./cardlist-users/cardlist-user";
import { useEffect, useState } from "react";
import UserForm from "./users-form/user-form";
import { UserInfoDTO } from "./models/users-DTO";
import { getUsersById } from "./users-service";
import { useNavigate, useParams } from "react-router-dom";
import ImportExportCsv from "./../../../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../../../contexts/auth-context";

const Users: React.FC<{ exportApi?: string, importApi?: string, enabledUsers: string[] }> = ({ enabledUsers, importApi, exportApi }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState<UserInfoDTO[]>([]);
  const { id } = useParams<{ id: string }>();
  let timer: NodeJS.Timeout;
  const data = { originId: id };
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;

  const fetchUsers = async () => {
    if (id) {
      try {
        const data = await getUsersById(id);
        setUsers(data);
      } catch (error) {
        console.error("Error fetching scrap dealers: ", error);
      }
    }
    clearTimeout(timer)
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    const delay = 2000;
    timer = setTimeout(() => {
      fetchUsers();
    }, delay);
  };

  console.log(enabledUsers, profile, "users")
  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <UserForm userId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            <ImportExportCsv
              exportApi={exportApi}
              importApi={importApi}
              data={data}
              onClose={handleCloseModal}
            />
          </div>
          <br></br>
          <CardListUser onClose={handleCloseModal} users={users} />
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default Users;

import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import CardListChangelog from "./cardlist-changelog/cardlist-changelog";
import { useState } from "react";
import { useAuth } from "../../../../contexts/auth-context";
import { useNavigate } from "react-router-dom";

const Changelog: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <div className="page">
          <br></br>
          <CardListChangelog />
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default Changelog;

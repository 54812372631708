import React, { useEffect, useState } from "react";
import NoData from "../../../../../components/NoData/NoData";
import CardEmailsWarning from "../card-emails-warning/card-emails-warning";
import { EmailsWarningInfoDTO } from "../models/emails-warning-DTO";

interface cardlistProps {
  warnings: EmailsWarningInfoDTO[];
  onClose: () => void
}

const CardListEmailsWarning = ({ onClose, warnings }: cardlistProps) => {
  return (
    <div className="card-list">
      {warnings.map((item, index) => (
        <CardEmailsWarning onClose={onClose} key={index} item={item} />
      ))}
      {warnings.length === 0 && <NoData />}
    </div>
  );
};

export default CardListEmailsWarning;

import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";

import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import { ScrapsCategoryDTO } from "../../scraps-DTO";
import { getScrapCategoryById } from "../../scraps-service";
import { useAuth } from "../../../../contexts/auth-context";

const DropdownMenu = ({ show, onClose, options, position }: any) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose(null);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={menuRef}
      className="dropdown-menu"
      style={{ top: position.top, left: position.left }}
    >
      <ul>
        {options.map((option: any, index: any) => (
          <li key={index} onClick={() => onClose(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ScrapCategoryDetails: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [scrapsCategory, setScrapsCategory] = useState<ScrapsCategoryDTO | null>(null);
  const [tabs] = useState([
    { value: "1", label: t("label-panel") },
    { value: "register", label: t("label-register") },
    { value: "2", label: t("label-scrap-generators") },
    { value: "3", label: t("label-scrap-dealers") },
    { value: "to-deal", label: t("label-toDeal") },


  ]);
  const [tempTabs, setTempTabs] = useState<any[]>(tabs)

  const [selectedTab, setSelectedTab] = useState("register");
  const [previousTab, setPreviousTab] = useState("register");
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const fetchScrapCategory = async () => {
      if (!id) return;
      try {
        const data = await getScrapCategoryById(id);
        if (data) {
          setScrapsCategory(data);
        } else {
          console.error("Data is undefined");
        }
      } catch (error) {
        console.error("Error fetching scrap dealer:", error);
      }
    };
    fetchScrapCategory();
  }, [id]);

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    const allOptions = [...tempTabs];
    const matchedOption = allOptions.find(option => option.value === currentPath);
    if (matchedOption) {
      setSelectedTab(matchedOption.value);
      if (matchedOption.value === "others") {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    }
  }, [location]);

  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;

    if (newTab === "others") {
      setPreviousTab(selectedTab);
      const target = (event.target as HTMLElement).getBoundingClientRect();
      setDropdownPosition({ top: target.bottom + window.scrollY, left: target.left + window.scrollX });
      setShowDropdown(true);
    } else {
      setTempTabs([])
      setTempTabs(tabs);
      setSelectedTab(newTab);
      setShowDropdown(false);
      navigate(newTab);
    }
  };

  const handleCloseDropdown = (option: { value: string, label: string } | null) => {
    if (option && option.value != "others") {
      setTempTabs([])
      setTempTabs(tabs.filter(tab => tab.value !== option.value && tab.value !== "others").concat(option, { value: "others", label: t("label-others") }));
      setSelectedTab(option.value);
      navigate(option.value);
    } else {
      setSelectedTab(previousTab);
    }
    setShowDropdown(false);
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page page-centered">
        <h2>{scrapsCategory?.scrapsCategoryInfo.name}</h2>
        <SparkTabNavigation
          items={tempTabs}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />

      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapCategoryDetails;

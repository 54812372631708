import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Pages
import ComingSoon from "./pages/landing-page/landing-page";
import Login from "./pages/login/login";
import ScrapGenerators from "./pages/scrap-generators/scrap-generators";
import { AuthProvider } from "./contexts/auth-context";
import ScrapDealers from "./pages/scrap-dealers/scrap-dealers";
import NewScrapDealerForm from "./pages/scrap-dealers/components/new-scrap-dealer-form/new-scrap-dealer-form";
import { AlertProvider } from "./contexts/alert-context";
import ScrapDealerDetails from "./pages/scrap-dealers/components/scrap-dealer-details/scrap-dealer-details";
import ScrapDealerDetailsRegister from "./pages/scrap-dealers/components/scrap-dealer-details/components/scrap-dealer-details-register/scrap-dealer-details-register";
import ScrapDealerDetailsBranchs from "./pages/scrap-dealers/components/scrap-dealer-details/components/scrap-dealer-details-branchs/scrap-dealer-details-branchs";
import PrivacyNotice from "./pages/privacy-notice/privacy-notice";
import CreditAnalysis from "./pages/scrap-dealers/components/scrap-dealer-details/components/credit-analysis/credit-analysis";
import NewScrapGeneratorForm from "./pages/scrap-generators/components/new-scrap-generator-form/new-scrap-generator-form";
import ScrapGeneratorDetailsBranchs from "./pages/scrap-generators/components/scrap-generator-details/components/scrap-generator-details-branchs/scrap-generator-details-branchs";
import ScrapGeneratorDetailsRegister from "./pages/scrap-generators/components/scrap-generator-details/components/scrap-generator-details-register/scrap-generator-details-register";
import ScrapGeneratorDetails from "./pages/scrap-generators/components/scrap-generator-details/scrap-generator-details";
import ScrapAdminn from "./pages/scraps/scrap-admin-generator-view/scrap-admin";
import ScrapsFormGenerator from "./pages/scraps/scraps-form-generator/scraps-form-generator";
import Settings from "./pages/settings/settings";
import Contacts from "./pages/contacts/contacts";
import NotFound from "./components/NotFound/NotFound";
import Logout from "./components/Logout/Logout";
import Users from "./pages/settings/components/users/users";
import EmailsWarnings from "./pages/settings/components/Emails-warning/emails-warning";
import Changelog from "./pages/settings/components/Changelog/changelog";
import PartnerRequirement from "./pages/settings/components/partners-requirements/partner-requirement";
import ContainerTypes from "./pages/settings/components/Containers-types/container-types";
import Banks from "./pages/settings/components/Banks/banks";
import PartnerRequirementCheck from "./pages/scrap-dealers/components/partners-requirement-check/partner-requirement-check";
import ScrapForm from "./pages/scraps/scrap-admin-generator-view/components/scrap-form/scrap-form";
import CountryConfig from "./pages/settings/components/Country-config/country-config";
import CountryConfigForm from "./pages/settings/components/Country-config/country-config-form/country-config-form";
import ScrapCategoryForm from "./pages/scraps/scrap-category/scrap-category-form/scrap-category-form";
import ScrapCategoryDetails from "./pages/scraps/scrap-category/scrap-details/scrap-details";
import ScrapAdminScrapsToDealView from "./pages/scraps/scrap-admin-scraps-to-deal-view/scrap-admin-scraps-to-deal-view";
import ScrapTab from "./pages/scraps/scrap-tab/scrap-tab";
import ScrapCategories from "./pages/scraps/scrap-category/scrap-categories";
import ScrapAdminScrapsCategoryToDealView from "./pages/scraps/scrap-admin-scraps-category-to-deal-view/scrap-admin-scraps-category-to-deal-view";
import ScrapAdminDealerView from "./pages/scraps/scrap-admin-dealer-view/scrap-admin-dealer-view";
import ScrapAdminScrapsContractedView from "./pages/scraps/scrap-admin-scraps-contracted-view/scrap-admin-scraps-contracted-view";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // ROTA PRINCIPAL
    children: [
      {
        path: "/",
        element: <ComingSoon />, // ROTA PUBLICA
      },
      {
        path: "/privacy-notice",
        element: <PrivacyNotice />, // ROTA PUBLICA
      },
      {
        path: "/login",
        element: <Login />, // ROTA PUBLICA
      },
      {
        path: "/settings/:id",
        element: <Settings enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/settings/:id/users",
            element: (
              <Users
                enabledUsers={["admin-bosch"]}
                importApi="importusersboschcsv"
                exportApi="exportusersboschcsv"
              />
            ),
          },
          {
            path: "/settings/:id/emails-warning",
            element: <EmailsWarnings enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/changelog",
            element: <Changelog enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/partners-requirements",
            element: <PartnerRequirement enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/container-types",
            element: <ContainerTypes enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/banks",
            element: <Banks enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/country-config",
            element: <CountryConfig enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/settings/:id/country-config/:countryId/edit",
            element: <CountryConfigForm enabledUsers={["admin-bosch"]} />,
          },
        ],
      },
      {
        path: "/country-config-form",
        element: <CountryConfigForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/logout",
        element: <Logout />, // ROTA PUBLICA
      },

      {
        path: "/scrap-generators",
        element: <ScrapGenerators enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-generators-new",
        element: <NewScrapGeneratorForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-generators-new/:headquarterParamId",
        element: <NewScrapGeneratorForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-generators-details/:id",
        element: <ScrapGeneratorDetails enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scrap-generators-details/:id/register",
            element: <ScrapGeneratorDetailsRegister enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/branchs",
            element: <ScrapGeneratorDetailsBranchs enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/contacts",
            element: <Contacts enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/scraps",
            element: <ScrapAdminn enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/scraps-form",
            element: <ScrapForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/scraps-form/:scrapId",
            element: <ScrapForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-generators-details/:id/users",
            element: (
              <Users
                importApi="importusersscrapgeneratorscsv"
                exportApi="exportusersscrapgeneratorscsv"
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
        ],
      },
      {
        path: "/scrap-generators-edit/:id",
        element: <NewScrapGeneratorForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers",
        element: <ScrapDealers enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers-edit/:id",
        element: <NewScrapDealerForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers-new",
        element: <NewScrapDealerForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers-new/:headquarterParamId",
        element: <NewScrapDealerForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-dealers-details/:id",
        element: <ScrapDealerDetails enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scrap-dealers-details/:id/register",
            element: <ScrapDealerDetailsRegister enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/branchs",
            element: <ScrapDealerDetailsBranchs enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/contacts",
            element: <Contacts enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/scraps",
            element: <ScrapAdminDealerView enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/users",
            element: (
              <Users
                importApi="importusersscrapdealerscsv"
                exportApi="exportusersscrapdealerscsv"
                enabledUsers={["admin-bosch"]}
              />
            ),
          },
          {
            path: "/scrap-dealers-details/:id/credit-analysis",
            element: <CreditAnalysis enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-dealers-details/:id/requirement-check",
            element: <PartnerRequirementCheck enabledUsers={["admin-bosch"]} />,
          },
        ],
      },
      {
        path: "/scrap-categories-form",
        element: <ScrapCategoryForm enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scraps",
        element: <ScrapTab enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scraps/scraps-category",
            element: <ScrapCategories enabledUsers={["admin-bosch"]} />
          },
          {
            path: "/scraps/scraps-to-deal",
            element: <ScrapAdminScrapsToDealView enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scraps/contracts",
            element: <ScrapAdminScrapsContractedView enabledUsers={["admin-bosch"]} />,
          },
        ],
      },
      {
        path: "/users/:id",
        element: <Users enabledUsers={["admin-bosch"]} />,
      },
      {
        path: "/scrap-categories-details/:id",
        element: <ScrapCategoryDetails enabledUsers={["admin-bosch"]} />,
        children: [
          {
            path: "/scrap-categories-details/:id/edit",
            element: <ScrapCategoryForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-categories-details/:id/register",
            element: <ScrapCategoryForm enabledUsers={["admin-bosch"]} />,
          },
          {
            path: "/scrap-categories-details/:id/to-deal",
            element: <ScrapAdminScrapsCategoryToDealView enabledUsers={["admin-bosch"]} />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/:id/scraps-form",
        element: <ScrapsFormGenerator enabledUsers={["admin-bosch"]} />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AlertProvider>
        <RouterProvider router={router} />
      </AlertProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();


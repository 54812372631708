import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/auth-context";

const DropdownMenu = ({ show, onClose, options, position }: any) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose(null);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={menuRef}
      className="dropdown-menu"
      style={{ top: position.top, left: position.left }}
    >
      <ul>
        {options.map((option: any, index: any) => (
          <li key={index} onClick={() => onClose(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Settings: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabs] = useState([
    { value: "users", label: t("label-users") },
    { value: "emails-warning", label: t("label-emails-warning") },
    { value: "partners-requirements", label: t("label-partners-requirements") },
    { value: "changelog", label: t("label-logs") },
    { value: "others", label: t("label-others") },
  ]);
  const [tempTabs, setTempTabs] = useState<any[]>(tabs)
  const [otherOptions, setOtherOptions] = useState([
    { value: "3", label: t("label-approval-instances") },
    { value: "banks", label: t("label-banks") },
    { value: "country-config", label: t("label-country-config") },
    { value: "container-types", label: t("material-container-types") },
  ]);
  const [selectedTab, setSelectedTab] = useState("register");
  const [previousTab, setPreviousTab] = useState("register");
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;

  useEffect(() => {

  }, [id]);

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    const allOptions = [...tempTabs, ...otherOptions];
    const matchedOption = allOptions.find(option => option.value === currentPath);
    if (matchedOption) {
      setSelectedTab(matchedOption.value);
      if (matchedOption.value === "others") {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    }
  }, [location]);

  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;

    if (newTab === "others") {
      setPreviousTab(selectedTab);
      const target = (event.target as HTMLElement).getBoundingClientRect();
      setDropdownPosition({ top: target.bottom + window.scrollY, left: target.left + window.scrollX });
      setShowDropdown(true);
    } else {
      setTempTabs([])
      setTempTabs(tabs);
      setSelectedTab(newTab);
      setShowDropdown(false);
      navigate(newTab);
    }
  };

  const handleCloseDropdown = (option: { value: string, label: string } | null) => {
    if (option && option.value != "others") {
      setTempTabs([])
      setTempTabs(tabs.filter(tab => tab.value !== option.value && tab.value !== "others").concat(option, { value: "others", label: t("label-others") }));
      setSelectedTab(option.value);
      navigate(option.value);
    } else {
      setSelectedTab(previousTab);
    }
    setShowDropdown(false);
  };

  if (loading) {
    return <></>;
  }

  console.log(userDoc)
  console.log(enabledUsers, profile, "users")
  if (enabledUsers.includes(profile || "public") || location.pathname != `/settings/${id}`) {
    console.log("settings")
    return (
      <div className="page page-centered">
        <h2>{t("navigation.settings")}</h2>
        <SparkTabNavigation
          items={tempTabs}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />
        <DropdownMenu
          show={showDropdown}
          onClose={handleCloseDropdown}
          options={otherOptions}
          position={dropdownPosition}
        />
      </div>
    );
  } else {
    console.log("settings/error")
    navigate("/page-not-found")
    return <></>
  }
};

export default Settings;
import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth, firestore } from "../utils/firebase-config";
import { UserInfoDTO } from "../pages/settings/components/users/models/users-DTO";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../assets/enums/firebase-colections";

interface AuthContextType {
    currentUser: User | null;
    userDoc: UserInfoDTO | null;
    loading: boolean;
}

export const AuthContext = createContext<AuthContextType>({ currentUser: null, userDoc: null, loading: true });
export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [userDoc, setUserDoc] = useState<UserInfoDTO | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(firestore, COLLECTIONS.USERS, user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    updateDoc(userRef, {
                        lastAccess: new Date().toLocaleString(),
                    });

                    setUserDoc(userDoc.data() as UserInfoDTO);
                }
                setCurrentUser(user);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser, userDoc, loading }}>
            {children}
        </AuthContext.Provider>
    );
};


import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import { useEffect, useState } from "react";
import CardListEmailsWarning from "./cardlist-emails-warning/cardlist-emails-warning";
import EmailsWarningForm from "./emails-warning-form/emails-warning-form";
import { getEmailsWarningsById } from "./emails-warning-service";
import { useNavigate, useParams } from "react-router-dom";
import { EmailsWarningInfoDTO } from "./models/emails-warning-DTO";
import { useAuth } from "../../../../contexts/auth-context";

const EmailsWarnings: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warnings, setWarnings] = useState<EmailsWarningInfoDTO[]>([]);
  const { id } = useParams<{ id: string }>();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;

  const fetchWarnings = async () => {
    try {
      if (id) {
        const data = await getEmailsWarningsById(id);
        setWarnings(data);
      }
    } catch (error) {
      console.error("Error fetching scrap dealers: ", error);
    }
  };

  useEffect(() => {
    fetchWarnings();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    fetchWarnings();
    setIsModalOpen(false);
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <EmailsWarningForm userId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
          </div>
          <br></br>
          <CardListEmailsWarning onClose={handleCloseModal} warnings={warnings} />
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default EmailsWarnings;

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { ExpirationDTO } from "./models/ExpirationDTO";
import { saveNew, saveNewWithId, update } from "../../services/persistence";
import { addDaysAndTransformDateStringToDate } from "../../services/date-format";

// add new 
export async function setExpirationDocument(data: ExpirationDTO, id: string) {
  data.startWarningDate = await addDaysAndTransformDateStringToDate(data.expirationDate, -data.warningPeriod);
  if (data.id == undefined) {
    saveNewWithId(COLLECTIONS.EXPIRATION_DOCUMENTS, data, id);
  } else {
    update(COLLECTIONS.EXPIRATION_DOCUMENTS, data, data.id);
  }
}

export async function getExpirationDocumentByOriginId(
  id: string
): Promise<ExpirationDTO[]> {
 
  const contactsCollectionRef = collection(firestore, COLLECTIONS.EXPIRATION_DOCUMENTS);

  const q = query(
    contactsCollectionRef,
    where("originId", "==", id),
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ExpirationDTO[];
  return items;
}


export async function getExpirationDocument(
  id: string
): Promise<ExpirationDTO> {
  const docRef = doc(firestore, COLLECTIONS.EXPIRATION_DOCUMENTS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ExpirationDTO;
}

import React, { useEffect, useState } from "react";
import { ContactInfoDTO } from "../contacts-DTO";
import CardContact from "../card-contact/card-contact";
import NoData from "../../../components/NoData/NoData";

interface cardlistProps {
  contacts: ContactInfoDTO[];
  onClose: () => void;
}

const CardListContacts = ({ onClose, contacts }: cardlistProps) => {
  return (
    <div className="card-list">
      <br></br>
      {contacts.map((item, index) => (
        <CardContact onClose={onClose} key={index} item={item} />
      ))}
      {contacts.length === 0 && <NoData />}
    </div>
  );
};

export default CardListContacts;

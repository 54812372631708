import { useTranslation } from "react-i18next";
import ilustration from "../../../../assets/img/circular-economy-header.webp";
import icon from "../../../../assets/img/icon.svg";
import { Analytics, logEventAnalytics } from "../../../../services/analytics";

const CardLandipageDetals: any = ({ }) => {
  const { t } = useTranslation();

  const subject = t("mail.subject");
  const body = t("mail.body");

  return (
    <div className="container-img">
      <div>
        <img
          className="img-principal"
          src={ilustration}
          alt="ilustration - pile of scrap"
        />
      </div>
      <div>
        <div className="container-text">
          <p className="landing-page-description">
            {t("landing-page.description-one")}
          </p>
          <p className="landing-page-description">
            {t("landing-page.description-two")}
          </p>
          <p className="landing-page-description">
            {t("landing-page.description-three")}
          </p>
        </div>
        <div className="container-representative font-weight none">
          <div>
            <a className="title-click" href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} onClick={() => logEventAnalytics(Analytics.LANDING_PAGE_CONSULTANT)}>{t("landing-page.representative")}</a>
            <a className="title-clicks" href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} onClick={() => logEventAnalytics(Analytics.LANDING_PAGE_CONSULTANT)}>{t("landing-page.representatives")}</a>
          </div>
          <div className="icon"><img width={25} height={25} src={icon} alt="ilustration - Bosch Persons construct layout" /></div>
        </div>
      </div>
    </div>
  );
};

export default CardLandipageDetals;

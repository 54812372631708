import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../contexts/alert-context";
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { app } from "../../../utils/firebase-config";
import downloadIconWhite from "../../../assets/svgs/download_white.svg";
import { callFunction } from "../../../services/persistence";

interface ModalProps {
  onClose: () => void;
  apiData: string;
  templateUrl: string;
  data?: any;
}

const UploadCsvModal: React.FC<ModalProps> = ({ onClose, apiData, templateUrl, data}) => {
  type FileState = File | null;

  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [file, setFile] = useState<FileState>(null);

  const handleSubmit = () => {

    if (!file) {
      alert(t("label-no-file"));
      return;
    }
    document.body.style.cursor = 'wait';
    const now = new Date();
    const storage = getStorage(app);
    const nomeArquivo = `imports/import_${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}.csv`;
    const storageRef = ref(storage, nomeArquivo);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        alert(t("upload-error"));
      },
      () => {
        callFunction(apiData,
          { ...{ bucketName: 'gs://' + storageRef.bucket, fileName: nomeArquivo }, ...data }
        ).then(e => {
          alert(t("upload-success"));
          onClose();
        }).finally(() => {
          document.body.style.cursor = 'auto';
          onClose();
        });
      }
    );
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p className="forget-password-title">{t("label-upload-csv")}</p>
        <p className="info-text">{t("label-upload-csv-information")}</p>

        <input type="file" id="fileInput" accept=".csv" title={t("label-upload-csv")}
          onChange={(e) => e.target.files && setFile(e.target.files[0])} />

        <button
          className="primary"
          type="button"
          onClick={() => handleSubmit()}
          title={t("label-send")}
        >
          {t("label-send")}
        </button>
        <a className="blue-with-icon button" href={templateUrl}>
          <button type="button" className="blue-with-icon button" title={t("label-download-csv-example")}>
            <img src={downloadIconWhite} alt="add icon" />
            <p>{t("label-download-csv-example")}</p>
          </button>
        </a>
      </div>
    </div>
  );
};

export default UploadCsvModal;

import React from "react";
import "../../styles/not-found.css";
import underConstruction from "../../assets/gifs/under_construction.gif";
import { useTranslation } from "react-i18next";

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="coming-soon-section page">
      <div className="title-description">
        <h2>{t("coming-soon.title")}</h2>

      </div>
      <img
        src={underConstruction}
        alt="ilustration - Bosch construct layout"
      />
    </div>
  );
};

export default NotFound;



export function extractSubstringFile(inputString: string): string | null {
    const regex = /---(.*?)\?/; // Regular expression to match the substring between "---" and "?"
    const match = inputString.match(regex); // Use the match method to find the substring
  
    if (match && match[1]) {
   return match[1]; // Return the matched substring
    } else {
   return null; // Return null if no match is found
    }
  }

  export function extractFileNameFromUrl(url: string): string | null {
    const decodedUrl = decodeURIComponent(url);
  
    const regex = /\/([^\/?#]+)(?:[?#]|$)/;
    const match = decodedUrl.match(regex);
  
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
import { useTranslation } from "react-i18next";
import globe from "../../../../assets/img/globe.svg";
import UP from "../../../../assets/img/icon-up.svg";
import { useState } from "react";

const Footer: any = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <span className="text-rodape">{t("landing-page.technology")}</span>

      <div className="container-rodape">
        <img src={globe} alt="icon globo" width={25} height={25} />
        <span>{t("landing-page.bosch")}</span>
      </div>

      <hr />
      <div className="rodape">
        <span>{t("landing-page.information")}</span>
        <span style={{ cursor: "pointer" }} onClick={openModal}>
          {t("landing-page.corporate")}
        </span>
        <a className="text-footer" href="/privacy-notice" target="_blank">
          {t("landing-page.data")}
        </a>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <img
            style={{ cursor: "pointer" }}
            onClick={scrollToTop}
            src={UP}
            alt="icon up"
            width={40}
            height={40}
          />
        </div>
      </div>

      <div className="rodape-respondivo">
        <div className="description-rodape">
          <span onClick={openModal}>{t("landing-page.corporate")}</span>
          <img
            className="cursor"
            onClick={scrollToTop}
            src={UP}
            alt="icon up"
            width={40}
            height={40}
          />
        </div>
        <a className="text-footer" href="/privacy-notice" target="_blank">
          {t("landing-page.data")}
        </a>
        <span>{t("landing-page.information")}</span>
      </div>

      {isOpen && (
        <div className="modal-corporate">
          <div className="modal">
            <div className="modal-information">
              <div className="modal-flex">
                <span className="text-modal">{t("rodape.contact")}</span>
                <div className="description-modal">
                  <span>{t("rodape.email")}</span>
                  <span>{t("rodape.phone")}</span>
                </div>
              </div>

              <div className="modal-flex">
                <span className="text-modal">{t("rodape.address")}</span>
                <div className="description-modal">
                  <span>{t("rodape.address-one")}</span>
                  <span>{t("rodape.address-two")}</span>
                </div>
              </div>
            </div>
            <button onClick={closeModal}>{t("rodape.close")}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;

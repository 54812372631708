import "../scrap.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { getScrapCategories } from "../scraps-service";
import { ScrapsCategoryInfoDTO } from "../scraps-DTO";
import { useAuth } from "../../../contexts/auth-context";

const ScrapTab: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [selectedTab, setSelectedTab] = useState("scraps-category");

  const tabs = [
    { value: "scraps-category", label: t("label-scrap-categories") },
    { value: "scraps-to-deal", label: t("label-toDeal") },
    { value: "contracts", label: t("statusEnum.CONTRACTED") },
  ];

  const [scrapCategories, setScrapCategories] = useState<
    ScrapsCategoryInfoDTO[]
  >([]);

  const fetchScrapCategories = async () => {
    try {
      const data = await getScrapCategories();
      setScrapCategories(data);
    } catch (error) {
      console.error("Error fetching scrap categories: ", error);
    }
  };

  useEffect(() => {
    fetchScrapCategories();
  }, []);

  const handleCloseModal = () => {
    fetchScrapCategories();
  };
  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;

    setSelectedTab(newTab);

    navigate(newTab);
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <SparkTabNavigation
          items={JSON.stringify(tabs)}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />

      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapTab;

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import { COLLECTIONS } from "../../../assets/enums/firebase-colections";
import {
  callFunction,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../../services/persistence";
import {
  DealersInvitedDTO,
  ObservationsDTO,
  ScrapBidDTO,
  ScrapContractDTO,
  ScrapDTO,
  ScrapInfoDTO,
  ScrapNegotiationDTO,
} from "./components/scrap-form/models/scrap-DTO";
import { ContactInfoDTO } from "../../scrap-generators/scrap-generators-DTO";
import { t } from "i18next";
import { ScrapGeneratorInfoDTO } from "../../scrap-generators/scrap-generators-DTO";
import { ScrapDealerInfoDTO } from "../../scrap-dealers/scrap-dealer-DTO";
import { setExpirationDocument } from "../../../components/Expiration/ExpirationService";

export async function addNewScrapAdmin(
  data: ScrapDTO,
  originId: string,
  updateOnlyInvitedTable: boolean = false
): Promise<string | void> {
  data.scrapInfo.targetPrice = data.scrapNegotiation.targetPrice;
  data.scrapInfo.originId = originId;
  data.scrapInfo.originName = await getOriginName(originId);

  if (!data.scrapNegotiation.bidNegotiationType) {
    data.scrapNegotiation.bidNegotiationType = "open";
  }

  if (data.scrapInfo.containerNeeded != false) {
    data.scrapInfo.containerNeeded = true;
  }
  
  if (
    data.scrapInfo.contractDealer != undefined &&
    data.scrapInfo.contractDealer.length > 1
  ) {
    data.scrapInfo.contractDealerName = await getDealerName(
      data.scrapInfo.contractDealer
    );
  }

  if (data.id == undefined) {
    const docRef = await saveNewAsync(
      COLLECTIONS.SCRAP_BY_GENERATOR,
      data.scrapInfo
    );

    await Promise.all([
      saveNewWithId(
        COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
        data.dealersInvitedTable,
        docRef.id
      ),
   
      ...(data.scrapContract.expirationDocument != null && data.scrapContract.expirationDocument.hasExpirationDate != false
        ? [
            ((data.scrapContract.expirationDocument.originId = docRef.id),
            (data.scrapContract.expirationDocument.origin = `${
              data.scrapInfo.originName || " "
            } - ${data.scrapInfo.contractDealerName || " "} (${
              data.scrapInfo.scrapCategory.name || " "
            })`),
            setExpirationDocument(
              data.scrapContract.expirationDocument,
              docRef.id
            )),
          ]
        : []),
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id),
      saveNewWithId(
        COLLECTIONS.SCRAP_BY_GENERATOR_NEGOTIATION,
        data.scrapNegotiation,
        docRef.id
      ),
      data.scrapBid.bidValue != undefined
        ? saveNewWithId(
            COLLECTIONS.SCRAP_BY_GENERATOR_BID,
            data.scrapBid,
            docRef.id
          )
        : Promise.resolve(),
      data.scrapInfo.contractDealer != undefined
        ? saveNewWithId(
            COLLECTIONS.SCRAP_BY_GENERATOR_CONTRACT,
            data.scrapContract,
            docRef.id
          )
        : Promise.resolve(),
    ]);

    return docRef.id;
  } else {
    if (updateOnlyInvitedTable) {
      await Promise.all([
        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
          data.dealersInvitedTable,
          data.id
        ),
        update(COLLECTIONS.SCRAP_BY_GENERATOR_BID, data.scrapBid, data.id),
      ]);
    } else {
      await Promise.all([
        update(COLLECTIONS.SCRAP_BY_GENERATOR, data.scrapInfo, data.id),
        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
          data.dealersInvitedTable,
          data.id
        ),
        ...(data.scrapContract.expirationDocument != null && data.scrapContract.expirationDocument.hasExpirationDate != false
          ? [
              (data.scrapContract.expirationDocument.originId = data.id),
              (data.scrapContract.expirationDocument.origin = `${
                data.scrapInfo.originName
              } - ${data.scrapInfo.contractDealerName || " "} (${
                data.scrapInfo.scrapCategory.name || " "
              })`),
              setExpirationDocument(
                data.scrapContract.expirationDocument,
                data.id
              ),
            ]
          : []),
        update(COLLECTIONS.OBSERVATIONS, data.observations, data.id),
        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_NEGOTIATION,
          data.scrapNegotiation,
          data.id
        ),
        data.scrapBid.bidValue != undefined
          ? update(COLLECTIONS.SCRAP_BY_GENERATOR_BID, data.scrapBid, data.id)
          : Promise.resolve(),
        data.scrapInfo.contractDealer != undefined
          ? update(
              COLLECTIONS.SCRAP_BY_GENERATOR_CONTRACT,
              data.scrapContract,
              data.id
            )
          : Promise.resolve(),
      ]);
    }
  }
}

async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_GENERATORS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapGeneratorInfoDTO;
  return dealer.tradingName;
}

async function getDealerName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

// get all scrapAdmins
export async function getScrapAdmin(): Promise<ScrapInfoDTO[]> {
  const querySnapshot = await getDocs(
    collection(firestore, COLLECTIONS.SCRAP_DEALERS)
  );
  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ScrapInfoDTO[];
  return items;
}

// get scrapAdmin details
export const getScrapAdminById = async (
  id: string
): Promise<ScrapDTO | null> => {
  const scrapInfo = await getscrapInfo(id);
  const observations = await getScrapAdminObservations(id);
  const scrapNegotiation = await getScrapAdminNegotiation(id);
  const scrapBid = await getScrapAdminBid(id);
  const scrapContract = await getScrapAdminContract(id);
  const dealersInvitedTable = await getScrapAdminInvitedDelaersTable(id);

  return {
    id,
    scrapInfo,
    observations,
    scrapNegotiation,
    scrapBid,
    scrapContract,
    dealersInvitedTable,
  };
};

export async function getScrapAdminInvitedDelaersTable(
  id: string
): Promise<DealersInvitedDTO> {
  const docRef = doc(
    firestore,
    COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
    id
  );
  const docSnap = await getDoc(docRef);

  return docSnap.data() as DealersInvitedDTO;
}

export async function getScrapAdminContract(
  id: string
): Promise<ScrapContractDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR_CONTRACT, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapContractDTO;
}

export async function getScrapAdminBid(id: string): Promise<ScrapBidDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR_BID, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapBidDTO;
}

export async function getscrapInfo(id: string): Promise<ScrapInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapInfoDTO;
}

export async function getScrapAdminNegotiation(
  id: string
): Promise<ScrapNegotiationDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_BY_GENERATOR_NEGOTIATION, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapNegotiationDTO;
}

export async function getScrapAdminObservations(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

export async function fetchFulfilledRequirements(id: string): Promise<any> {
  const docRef = doc(
    firestore,
    COLLECTIONS.PARTNER_REQUIREMENTS_DEALER_TOTAL,
    id
  );
  const docSnap = await getDoc(docRef);

  return docSnap.data();
}

export async function fetchPartnerRequirementsTotalByCountry(
  countryName: string
) {
  const partnerRequirementsCollectionRef = collection(
    firestore,
    COLLECTIONS.PARTNER_REQUIREMENTS
  );
  const q = query(
    partnerRequirementsCollectionRef,
    where("country", "in", [countryName])
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as any;

  return items;
}

export async function sendDealerEmail(scrapId: string, dealerId: string) {
  const docRefContact = doc(
    firestore,
    COLLECTIONS.CONTACTS,
    dealerId + "contact"
  );
  const docSnap = await getDoc(docRefContact);

  const contact = docSnap.data() as ContactInfoDTO;
  if (contact.email != null && contact.email.length > 0) {
    callFunction("sendemail", {
      to: contact.email,
      subject: t("invite-for-bid-subject"),
      content: t("invite-for-bid-subject"),
    });
  }

  const docRef = doc(
    firestore,
    COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
    scrapId
  );

  getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        const dealersInvitedListt = doc.data().dealersInvitedList;

        const updatedDealersInvitedList = dealersInvitedListt.map(
          (dealer: { id: string }) => {
            if (dealer.id === dealerId) {
              return { ...dealer, inviteSended: true };
            }
            return dealer;
          }
        );

        update(
          COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
          { dealersInvitedList: updatedDealersInvitedList },
          scrapId
        );
      } else {
        console.log("Document not found");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
}

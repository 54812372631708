import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../utils/firebase-config";
import { COLLECTIONS } from "../assets/enums/firebase-colections";

export interface ContainersTypeDTO {
    country: string;
    name: string;
    originId: string;
    size: string[]
}

export async function getContainersByCountryName(countryName: string) {
    const collectionRef = collection(firestore, COLLECTIONS.CONTAINERS_TYPE);
    const q = query(collectionRef, where("country", "==", countryName));
    const querySnapshot = await getDocs(q);
    
    const containers: ContainersTypeDTO[] = [];
    querySnapshot.forEach((doc) => {
        containers.push(doc.data() as ContainersTypeDTO);
    });

    return containers;
}
import React, { useEffect, useState, ChangeEvent, DragEvent } from "react";
import '../scrap.css';
import { useParams, useNavigate } from "react-router-dom";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import { t } from "i18next";
import { getAdrressByEntityId } from "../../../services/address-service";
import { getCountryConfigById } from "../../../services/address-service";
import { getScrapCategories } from "../scraps-service";
import { CurrencyInput } from "react-currency-mask";
import ScrapCategoryInputAutocomplete from "../../../components/ScrapCategoryInputAutocomplete/ScrapCategoryInputAutocomplete";
import { ScrapRegisterForm } from "./scraps-form-generator-DTO";
import { addNewScrapGeneratorForm } from "./scraps-form-generator-service";
import { ContainersTypeDTO } from "../../../services/containers-service";
import { getContainersByCountryName } from "../../../services/containers-service";
import { ScrapsCategoryDTO } from "../scraps-DTO";
import { useAlert } from "../../../contexts/alert-context";
import { useAuth } from "../../../contexts/auth-context";

interface Options {
    value: string;
    display: string;
}

interface Input {
    type: string;
    options?: Options[];
    text?: string;
    dynamicPickers?: { [key: string]: Options[] };
    size: string;
    placeholder?: string
}

interface Question {
    question: string;
    required: boolean;
    hasFoto: boolean;
    photoPath: string[];
    inputs: Input[];
}

interface Ruler {
    questions: { [key: string]: Question };
    answers: { [key: string]: { [inputIndex: string]: string[] } };
}

interface ContainerData {
    has: string;
    type: string;
    size: string;
    quantity: string;
}

interface PickerAndLoaderProps {
    handlerData: (questionKey: string, inputIndex: number, value: string) => void;
    questionKey: string;
    inputIndex: number;
    expectedValue: string;
    input: Input;
}

interface NumericWithButtonsProps {
    placeholder: string;
    handler: (questionKey: string, inputIndex: number, value: string) => void;
    questionKey: string;
    inputIndex: number;
}

interface RadioAndPickersProps {
    handlerData: (questionKey: string, inputIndex: number, value: { [key: string]: string }) => void;
    questionKey: string;
    inputIndex: number;
    input: Input;
    containers: ContainersTypeDTO[]
}

interface Suggestion {
    display: string;
    value: string;
    image?: string;
}

interface CurrencyData {
    currency: string
    locale: string
    currencyName: string
    currencyLabel: string
}

interface AutoCompleteAndPhotoProps {
    handlerData: (questionKey: string, inputIndex: number, value: { [key: string]: string }) => void;
    questionKey: string;
    inputIndex: number;
}

interface AddressProps {
    handler: (questionKey: string, inputIndex: number, value: string) => void;
    questionKey: string;
    inputIndex: number;
    address: any
}

const NumericWithButtons: React.FC<NumericWithButtonsProps> = ({ placeholder, handler, questionKey, inputIndex }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        handler(questionKey, inputIndex, `${value}`);
    }, [value]);

    return (
        <div className="numeric-container">
            <button onClick={() => setValue(value <= 0 ? 0 : value - 1)} className="numeric-button">-</button>
            <input
                type="number"
                value={value}
                onChange={(e) => setValue(parseInt(e.target.value))}
                className="numeric-input"
                placeholder={placeholder}
            />
            <button onClick={() => setValue(value + 1)} className="numeric-button">+</button>
        </div>
    );
};

const PickerAndLoader: React.FC<PickerAndLoaderProps> = ({ handlerData, questionKey, inputIndex, expectedValue, input }) => {
    const [pickerValue, setPickerValue] = useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setPickerValue(value);
        handlerData(questionKey, inputIndex, value);
    };

    return (
        <div>
            <select onChange={handleChange} className="picker-select">
                <option value={""}>{t("scraps-form.select")}</option>
                {input.options?.map((option, index) => (
                    <option key={index} value={option.value}>{option.display}</option>
                ))}
            </select>
            {pickerValue === expectedValue && (
                <div className="loader-container">
                    <p className="loader-text">{t("scraps-form.enabled-bid-participants")}</p>
                    <ImportExportCsv onClose={() => { }} importApi="importdealersprivatebid" />
                </div>
            )}
        </div>
    );
};

const ImageUploader: React.FC = () => {
    const [images, setImages] = useState<string[]>([]);

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleFiles = (files: File[]) => {
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        const newImages = imageFiles.map(file => URL.createObjectURL(file));
        setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files ? Array.from(e.target.files) : [];
        handleFiles(files);
    };

    return (
        <div
            className="dropzone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleInputChange}
                className="inputFile"
            />
            <p>{t('scraps-form.drop-area')}</p>
            <div className="imageContainer">
                {images.map((image, index) => (
                    <img key={index} src={image} alt={`uploaded ${index}`} className="image" />
                ))}
            </div>
        </div>
    );
};

const AutoCompleteAndPhoto: React.FC<AutoCompleteAndPhotoProps> = ({ handlerData, questionKey, inputIndex }) => {
    const [photo, setPhoto] = useState<string>()

    return (
        <div style={{ marginTop: "24px" }}>
            <ScrapCategoryInputAutocomplete
                label={""}
                placeholder={""}
                onChange={(value) => {
                    setPhoto((value as any).image)
                    handlerData(questionKey, inputIndex, value)
                }}
            ></ScrapCategoryInputAutocomplete>
            {photo && <img src={photo} width={200} height={200} style={{ marginTop: "24px" }} alt="" />}
        </div>
    )
}

const RadioAndPickers: React.FC<RadioAndPickersProps> = ({ handlerData, questionKey, inputIndex, input, containers }) => {
    const [selected, setSelected] = useState<string>("");
    const [pickerValues, setPickerValues] = useState<{ [key: string]: string }>({});
    const [containerData, setContainerData] = useState<ContainersTypeDTO[]>(containers)
    const [containerIndex, setContainerIndex] = useState<number | null>(null)

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSelected(value);
        if (value === "yes") {
            handlerData(questionKey, inputIndex, { ...pickerValues, has: "true" });
        } else {
            setPickerValues({});
            handlerData(questionKey, inputIndex, { has: "false" });
        }
    };

    const handlePickerChange = (pickerKey: string, value: any) => {
        const newPickerValues = { ...pickerValues, has: "true", [pickerKey]: pickerKey == "type" ? containerData[parseInt(value)].name : value };
        setPickerValues(newPickerValues);
        if (pickerKey == "type") return setContainerIndex(value)
        handlerData(questionKey, inputIndex, newPickerValues);
    };

    const handleNumericChange = (value: string) => {
        const newPickerValues = { ...pickerValues, has: "true", quantity: value };
        setPickerValues(newPickerValues);
        handlerData(questionKey, inputIndex, newPickerValues);
    };

    return (
        <div className="radio-and-pickers-container">
            <div className="radio-group">
                <div className="radio-option">
                    <input
                        type="radio"
                        name={`radio-${questionKey}-${inputIndex}`}
                        value="yes"
                        checked={selected === "yes"}
                        onChange={handleRadioChange}
                    />
                    {t('scraps-form.yes')}
                </div>
                <div className="radio-option">
                    <input
                        type="radio"
                        name={`radio-${questionKey}-${inputIndex}`}
                        value="no"
                        checked={selected === "no"}
                        onChange={handleRadioChange}
                    />
                    {t('scraps-form.no')}
                </div>
            </div>

            {selected === "yes" && (
                <div className="picker-container">
                    {input.dynamicPickers && Object.keys(input.dynamicPickers).map((pickerKey, idx) => (
                        <div key={idx} className="picker-item">
                            <p className="picker-label">{t(`scraps-form.question2.dynamic-picker.${pickerKey}`)} :</p>
                            <select
                                onChange={(e) => handlePickerChange(pickerKey, e.target.value)}
                                className="picker-select"
                                value={pickerValues[`${pickerKey}`]}
                            >
                                <option value={""}>{t("scraps-form.select")}</option>
                                {
                                    pickerKey == "type" && containerData.map((obj, index) =>
                                        <option key={index} value={index}>{obj.name}</option>
                                    )
                                }
                                {
                                    pickerKey == "size" && containerIndex && containerData[containerIndex].size.map((obj, index) =>
                                        <option key={index} value={obj}>{obj}</option>
                                    )
                                }
                            </select>
                        </div>
                    ))}
                    <div className="picker-item">
                        <p className="picker-label" style={{ marginBottom: "22px" }}>{t(`scraps-form.question2.dynamic-picker.quantity`)} :</p>
                        <NumericWithButtons
                            placeholder="picker-select"
                            handler={handleNumericChange}
                            questionKey={questionKey}
                            inputIndex={inputIndex}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const Address: React.FC<AddressProps> = ({ handler, questionKey, inputIndex, address }) => {
    const [data, setData] = useState(address);
    const [editMode, setEditMode] = useState(false);

    function handlerAdrresData(key: string, value: string) {
        setData((prevState: any) => ({
            ...prevState,
            [key]: value
        }));
    }

    function save() {
        handler(questionKey, inputIndex, data);
    }

    return (
        <div className="address-container">
            <p style={{ marginBottom: "12px", }}>{t("scraps-form.location.name")}</p>
            <div className="address-content">
                <div style={{ width: "100%" }}>
                    <p style={{ marginBottom: "5px" }}>{t("scraps-form.location.cep")}</p>
                    <input type="text" style={{ width: "100%" }} value={data.postalCode} onChange={(e) => handlerAdrresData("postalCode", e.target.value)} disabled={!editMode} />
                </div>
                <div style={{ width: "48%" }}>
                    <p style={{ marginBottom: "5px" }}>{t("scraps-form.location.state")}</p>
                    <input style={{ width: "100%" }} type="text" value={data.state} onChange={(e) => handlerAdrresData("state", e.target.value)} disabled={!editMode} />
                </div>
                <div style={{ width: "48%" }}>
                    <p style={{ marginBottom: "5px" }}>{t("scraps-form.location.number")}</p>
                    <input style={{ width: "100%" }} type="text" value={data.addressNumber} onChange={(e) => handlerAdrresData("addressNumber", e.target.value)} disabled={!editMode} />
                </div>
                <div style={{ width: "48%" }}>
                    <p style={{ marginBottom: "5px" }}>{t("scraps-form.location.address")}</p>
                    <input style={{ width: "100%" }} type="text" value={data.address} onChange={(e) => handlerAdrresData("address", e.target.value)} disabled={!editMode} />
                </div>
                <div style={{ width: "48%" }}>
                    <p style={{ marginBottom: "5px" }}>{t("scraps-form.location.city")}</p>
                    <input style={{ width: "100%" }} type="text" value={data.city} onChange={(e) => handlerAdrresData("city", e.target.value)} disabled={!editMode} />
                </div>
            </div>

            <button className="address-button" onClick={() => {
                if (!editMode) {
                    save();
                }
                setEditMode(!editMode);
            }}>{editMode ? t("scraps-form.location.save") : t("scraps-form.location.edit")}</button>
            {
                editMode && <button className="address-button-cancel" onClick={() => {
                    setData(address);
                    setEditMode(false);
                }}>{t("scraps-form.location.cancel")}</button>
            }
            {
                !editMode && <button className="scraps-form-button-save" style={{ marginLeft: "12px" }} onClick={() => save()}>
                    {t("scraps-form.location.confirm")}
                </button>
            }
        </div>
    );
};

const ScrapsFormGenerator: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
    const { id } = useParams<{ id: string }>();
    const [currencyLabel, setcurrencyLabel] = useState<CurrencyData>()
    const [scrapCategories, setScrapCategories] = useState<Suggestion[]>([]);
    const [containers, setContainers] = useState<any>()
    const [address, setAddress] = useState<any>()
    const { setAlert } = useAlert();
    const navigate = useNavigate();
    const { userDoc } = useAuth();
    const profile = userDoc?.profile;

    useEffect(() => {
        getAdrressByEntityId(id || "").then((data) => {
            if (data) {
                setAddress(data)
                getCountryConfigById(data.country).then((data) => {
                    if (data) {
                        setcurrencyLabel({ currency: data.currency, locale: data.locale, currencyName: data.currencyName, currencyLabel: data.currencyLabel })
                    }
                });
            }
        })
    }, [])

    async function fetchCategories() {
        try {
            const data = await getScrapCategories();
            const subArr = data.map((obj: { id: string; name: string, image: string }) => ({
                value: obj.id,
                display: obj.name,
                Image: obj.image
            }));
            setScrapCategories(subArr as Suggestion[]);
        } catch (error) {
            console.error("Error fetching scrap categories: ", error);
        }
    };

    const fetchContainers = async () => {
        const data = await getAdrressByEntityId(id as string);
        if (data) {
            const countryConfig = await getCountryConfigById(data.country);
            const response = await getContainersByCountryName(
                (countryConfig as any).country[0]
            );
            setContainers(response);
        }

    };

    useEffect(() => {
        fetchCategories()
        fetchContainers()
    }, [])

    const [ruler, setRuler] = useState<Ruler>({
        questions: {
            "0": {
                question: t('scraps-form.question0.name'), required: true, hasFoto: false, photoPath: [], inputs: [{
                    type: "autoCompletePicker", size: "100%", options: []
                }]
            },
            "1": { question: t('scraps-form.question1.name'), required: false, hasFoto: false, photoPath: [], inputs: [{ type: "text", size: "100%" }] },
            "2": {
                question: t('scraps-form.question2.name'), required: true, hasFoto: false, photoPath: [], inputs: [
                    { type: "numericWithButtons", size: "25%", placeholder: "Ex: 10" },
                    {
                        type: "picker", options: [
                            { value: "TONS", display: t('unityENUM.TONS') },
                            { value: "KILOS", display: t('unityENUM.KILOS') },
                            { value: "CUBIC_METERS", display: t('unityENUM.CUBIC_METERS') },
                        ], size: "30%"
                    },
                    {
                        type: "picker", size: "25%", options: [
                            { value: "DAILY", display: t('periodENUM.DAILY') },
                            { value: "WEEKLY", display: t('periodENUM.WEEKLY') },
                            { value: "MONTHLY", display: t('periodENUM.MONTHLY') },
                            { value: "YEARLY", display: t('periodENUM.YEARLY') },
                        ]
                    }
                ]
            },
            "3": {
                question: t('scraps-form.question3.name'), required: true, hasFoto: false, photoPath: [], inputs: [{
                    type: "picker", size: "100%", options: [
                        { value: "DAILY", display: t('periodENUM.DAILY') },
                        { value: "WEEKLY", display: t('periodENUM.WEEKLY') },
                        { value: "MONTHLY", display: t('periodENUM.MONTHLY') },
                        { value: "YEARLY", display: t('periodENUM.YEARLY') },
                    ]
                }]
            },
            "4": {
                question: t('scraps-form.question4.name'),
                required: true,
                hasFoto: false,
                photoPath: [],
                inputs: [{
                    type: "radio&pickers",
                    size: "100%",
                    dynamicPickers: {
                        "type": [{ value: "container", display: "container" }],
                        "size": [{ value: "1", display: "1KG" }, { value: "5", display: "5KG" }, { value: "10", display: "10KG" }, { value: "25", display: "25KG" }, { value: "50", display: "50KG" }],
                    }
                }]
            },
            "5": { question: t('scraps-form.question5.name'), required: true, hasFoto: false, photoPath: [], inputs: [{ type: "address", size: "100%" }] },
            "6": { question: `${t('scraps-form.question6.name')} {{currencyLabel}}/{{quest6}}`, required: true, hasFoto: false, photoPath: [], inputs: [{ type: "money", size: "100%" }] },
            "7": { question: t('scraps-form.question7.name'), required: false, hasFoto: false, photoPath: [], inputs: [{ type: "date", size: "100%" }] },
            "8": { question: t('scraps-form.question8.name'), required: true, hasFoto: false, photoPath: [], inputs: [{ type: "picker&download", size: "100%", options: [{ value: "open", display: t("scraps-form.question8.options.open") }, { value: "closed", display: t("scraps-form.question8.options.closed") }] }] },
            "9": { question: t('scraps-form.question9.name'), required: false, hasFoto: true, photoPath: [], inputs: [{ type: "files", size: "100%" }] },
            "10": { question: t('scraps-form.question10.name'), required: false, hasFoto: false, photoPath: [], inputs: [{ type: "text", size: "100%" }] },
        },
        answers: {},
    });

    const handlerData = (questionKey: string, inputIndex: number, value: string | { [key: string]: string }) => {
        setRuler((prevState: any) => ({
            ...prevState,
            answers: {
                ...prevState.answers,
                [questionKey]: {
                    ...prevState.answers[questionKey],
                    [inputIndex]: Array.isArray(value) ? [...(prevState.answers[questionKey]?.[inputIndex] || []), ...value] : [value]
                }
            }
        }));
    };

    const loadInput = (input: Input, questionKey: string, inputIndex: number) => {
        const commonProps = {
            onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handlerData(questionKey, inputIndex, e.target.value),
            style: { width: "100%", marginTop: 16 }
        };

        switch (input.type) {
            case "picker":
                return (
                    <select {...commonProps as any}>
                        <option value={""}>{t("scraps-form.select")}</option>
                        {input.options?.map((option, index) => (
                            <option key={index} value={option.value}>{option.display}</option>
                        ))}
                    </select>
                );
            case "autoCompletePicker":
                return scrapCategories.length > 0 && <AutoCompleteAndPhoto handlerData={handlerData} inputIndex={inputIndex} questionKey={questionKey}></AutoCompleteAndPhoto>
            case "text":
                return <input type="text" placeholder={input.placeholder || ""} {...commonProps as any} />;
            case "numeric":
                return <input type="number" placeholder={input.placeholder || ""} {...commonProps as any} />;
            case "money":
                return <CurrencyInput
                    currency={currencyLabel?.currency}
                    locale={currencyLabel?.locale}
                    defaultValue={0}
                    onChangeValue={(event, originalValue, maskedValue) => handlerData(questionKey, inputIndex, maskedValue.toString())}
                    InputElement={
                        <input
                            style={{ width: "100%", marginTop: 24 }}
                            type="text"
                            id="limitValue"
                        />
                    }
                />
            case "date":
                return <input type="date" placeholder={input.placeholder || ""} {...commonProps as any} />;
            case "files":
                return <div style={{ marginTop: 24 }}><ImageUploader></ImageUploader></div>
            case "picker&download":
                return (
                    <PickerAndLoader
                        handlerData={handlerData}
                        questionKey={questionKey}
                        inputIndex={inputIndex}
                        expectedValue="closed"
                        input={input}
                    />
                );
            case "radio&pickers":
                return containers && <RadioAndPickers
                    handlerData={handlerData}
                    questionKey={questionKey}
                    inputIndex={inputIndex}
                    input={input}
                    containers={containers}
                />
            case "textField":
                return <div style={{ margin: 24, width: "100%" }}>{input.text}</div>;
            case "numericWithButtons":
                return <div style={{ width: "100%", marginTop: 16 }}><NumericWithButtons
                    placeholder={input.placeholder || ""}
                    handler={handlerData}
                    questionKey={questionKey}
                    inputIndex={inputIndex}
                ></NumericWithButtons></div>
            case 'address':
                return address && <Address
                    handler={handlerData}
                    questionKey={questionKey}
                    inputIndex={inputIndex}
                    address={address}
                ></Address>
            default:
                return null;
        }
    };

    const shouldRenderQuestion = (key: string) => {
        const currentKey = parseInt(key);
        const previousKey = (currentKey - 1).toString();

        if (currentKey === 0) return true;

        if (currentKey >= 3 && (!ruler.answers["2"]?.["0"] || !ruler.answers["2"]?.["1"] || !ruler.answers["2"]?.["2"])) return false;

        let Quest5 = ruler.answers["4"]
        if (currentKey >= 5 && Quest5 && Quest5["0"] && typeof Quest5["0"][0] === 'object') {
            const containerData = Quest5?.["0"]?.[0] as ContainerData;
            if (containerData && containerData.has == "true" && (!containerData.type || !containerData.size || !containerData.quantity)) {
                return false
            }
        }

        const previousAnswer = ruler.answers[previousKey];

        if (!ruler.questions[key].required) {
            if (previousAnswer && Object.keys(previousAnswer).length > 0 && !ruler.answers[key]) {
                handlerData(key, 0, "");
            }
            return previousAnswer && Object.keys(previousAnswer).length > 0;
        }

        return previousAnswer && Object.keys(previousAnswer).length > 0;
    };

    const handleSave = async () => {
        const containerAnswers = ruler.answers["4"];
        let containerType = "";
        let containerSize = "";
        let containerQuantity = 0;

        if (ruler.answers["4"]?.["0"]?.[0] === "yes" && containerAnswers && containerAnswers["0"] && typeof containerAnswers["0"][0] === 'object') {
            const containerData = containerAnswers["0"][0] as ContainerData;
            containerType = containerData.type || "";
            containerSize = containerData.size || "";
            containerQuantity = parseInt(containerData.quantity || "0", 10);
        }

        const scrapsDTO: ScrapRegisterForm = {
            id: "",
            scrapCategory: ruler.answers["0"]?.["0"]?.[0] as unknown as ScrapsCategoryDTO,
            scrapDecription: ruler.answers["1"]?.["0"]?.[ruler.answers["1"]["0"].length - 1] || "",
            quantity: ruler.answers["2"]?.["0"]?.[0] || "",
            unity: ruler.answers["2"]?.["1"]?.[0] || "",
            period: ruler.answers["2"]?.["2"]?.[0] || "",
            pickUpFrequency: ruler.answers["3"]?.["0"]?.[0] || "",
            containerNeeded: ruler.answers["4"]?.["0"]?.[0] === "yes",

            containerType: containerType,
            containerSize: containerSize,
            containerQuantity: containerQuantity.toString(),

            address: ruler.answers["5"]?.["0"]?.[0] || "",
            targetPrice: ruler.answers["6"]?.["0"]?.[0] || "0",
            bidConclusionDate: ruler.answers["7"]?.["0"]?.[ruler.answers["6"]["0"].length - 1] || "",
            negociationType: ruler.answers["8"]?.["0"]?.[0] || "",
            // photos: ruler.answers["9"]?.["0"]?.[0] || [],
            photos: null,
            observations: ruler.answers["10"]?.["0"]?.[0] || "",
        };
        try {
            await addNewScrapGeneratorForm(scrapsDTO);
            setAlert({
                text: t("scraps-form.alert-successfully-registered"),
                type: "success",
            });
            navigate("/scrap-generators");
        } catch (e) {
            console.error("Erro ao cadastrar: ", e);
            setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
        }
    };

    const changeValues: { [key: string]: string | undefined } = {
        "{{quest6}}": t(`unityENUM.${ruler.answers["2"]?.["1"]?.[0]}`),
        "{{currencyLabel}}": currencyLabel?.currencyLabel
    };

    const replacePlaceholders = (text: string, values: { [key: string]: string | undefined }): string => {
        return Object.keys(values).reduce((acc, key) => {
            return acc.replace(new RegExp(key, 'g'), values[key] || '');
        }, text);
    };

    if (enabledUsers.includes(profile || "public")) {
        return (
            <div style={{ marginBottom: 100 }}>
                <h1>{t('scraps-form.main-text')}</h1>
                {Object.entries(ruler.questions).map(([key, question]) => (
                    shouldRenderQuestion(key) && (
                        <div key={key} className="question-item" style={{ marginTop: 54 }}>
                            <label style={{ fontSize: 24 }}>{replacePlaceholders(question.question, changeValues)}</label>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                                {question.inputs.map((input, idx) => (
                                    <div key={idx} style={{ width: input.size, alignItems: "center" }}>
                                        {loadInput(input, key, idx)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                ))}

                {
                    ruler.answers["9"] && <div className="scraps-form-save">
                        <button onClick={handleSave} className="scraps-form-button-save">{t('scraps-form.save')}</button>
                    </div>
                }
            </div>
        );
    } else {
        navigate("/page-not-found")
        return <></>
    }
};

export default ScrapsFormGenerator;

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import PartnerRequirementForm from "./partner-requirement-check-form/partner-requirement-check-form";
import { PartnerRequirementInfoDTO } from "../../../settings/components/partners-requirements/partner-requirement-DTO";
import { getPartnerRequirementByOriginId } from "../../../settings/components/partners-requirements/partner-requirement-service";
import { PartnerRequirementDealerDTO } from "./partner-requirement-check-DTO";
import { getPartnerRequirementDealer } from "./partner-requirement-check-service";
import { useAuth } from "../../../../contexts/auth-context";

const PartnerRequirementCheck: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [partnerRequirement, setPartnerRequirement] = useState<
    PartnerRequirementInfoDTO[]
  >([]);
  const [partnerRequirementDealer, setPartnerRequirementDealer] = useState<
    PartnerRequirementDealerDTO[]
  >([]);
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-requirement")}`,
    `${t("label-documents")}`,
    `${t("label-sdm-approval")}`,
    `${t("label-expiration")}`,
  ];

  const fetchPartnerRequirement = async () => {
    if (id) {
      try {
        const partnerRequirements = await getPartnerRequirementByOriginId(
          "bosch"
        );
        setPartnerRequirement(partnerRequirements);

        const dealerRequirements = await getPartnerRequirementDealer(id);
        setPartnerRequirementDealer(dealerRequirements);

        const combinedArray = partnerRequirements.map((partnerReq) => {
          const matchingDealerReq = dealerRequirements.find(
            (dealerReq) => dealerReq.requirementId === partnerReq.id
          );
          return { ...partnerReq, dealerRequirement: matchingDealerReq };
        });

        setTableData(combinedArray);
      } catch (error) {
        console.error("Error fetching scrap categories: ", error);
      }
    }
  };
  const [requirementId, setRequirementID] = useState(null || "");

  useEffect(() => {
    fetchPartnerRequirement();
  }, [id, setPartnerRequirement]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchPartnerRequirement();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <PartnerRequirementForm
              requirementId={requirementId ?? ""}
              onClose={handleCloseModal}
            />
          </div>
        )}
        <div className="page">
          <br />
          <div className="info-section">
        <SparkTable>
            <SparkTableHead className="flex-row" style={{ alignItens: "center" }}>
              <SparkTableRow>
                {tableHeader.map((header, index) => (
                  <SparkTableCell
                    style={{
                      alignItens: "center",
                      justifyContent: index != 0 ? "center" : "start",
                      display: "flex",
                      flexDirection: "row",
                    }}
                    key={index}
                  >
                    <strong>{header.toUpperCase()}</strong>
                  </SparkTableCell>
                ))}
              </SparkTableRow>
            </SparkTableHead>

            <SparkTableBody>
              {tableData.map((rowItem: any, rowIndex: any) => (
                <SparkTableRow
                  onClick={() => {
                    setRequirementID(rowItem.id);
                    handleOpenModal();
                  }}
                  key={rowIndex}
                >
                  <SparkTableCell>{rowItem.requirement}</SparkTableCell>
                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    <SparkIcon
                      className="icon-flag"
                      pallete="tertiary"
                      no-padding={true}
                      icName={
                        rowItem.dealerRequirement?.files?.length > 0
                          ? "upload"
                          : "less-minimize"
                      }
                    />
                  </SparkTableCell>

                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    {rowItem.dealerRequirement?.sdmApprovalStatus ? t(`approvalStatusENUM.${rowItem.dealerRequirement?.sdmApprovalStatus}`) : " - "}
                  </SparkTableCell>

                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    {t(`${rowItem.dealerRequirement?.expirationDocument?.expirationDate ?? " - "}`)}
                  </SparkTableCell>
                </SparkTableRow>
              ))}
            </SparkTableBody>
          </SparkTable>
        </div>
      </div>
    </div>);
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default PartnerRequirementCheck;

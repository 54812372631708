import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ChangelogDTO } from "../models/changelog-DTO";
import CardChangelog from "../card-changelog/card-changelog";
import NoData from "../../../../../components/NoData/NoData";
import { getChangelog } from "../changelog-service";

const CardListChangelog: React.FC = () => {
  const [changelog, setChangelog] = useState<ChangelogDTO[]>([]);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      const fetch = async () => {
        try {
          const data = await getChangelog();
          setChangelog(data);
        } catch (error) {
          console.error("Error fetching scrap dealers: ", error);
        }
      };

      fetch();
    }
  }, []);

  return (
    <div className="card-list">
      {changelog.map((item, index) => (
        <CardChangelog key={index} item={item} />
      ))}
      {changelog.length === 0 && <NoData />}
    </div>
  );
};

export default CardListChangelog;

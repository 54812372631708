import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChangelogDTO } from "../models/changelog-DTO";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { Timestamp } from "firebase/firestore";
import { formatTimestampDateAndTime } from "../../../../../services/date-format";

interface Props {
  item: ChangelogDTO;
}

const CardChangelog: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex-row card-dealer">
        <div className="flex-column infos">
          <div className="flex-row justify-between align-center">
            <h3 style={{ margin: 0 }}>{item.collection || "-"}</h3>
            <div className="flex-row align-center">
              <p style={{ marginRight: 12 }}>
                {formatTimestampDateAndTime(item.date as unknown as Timestamp)}
              </p>
              <SparkIcon
                icName={item.type === "add" ? "add-frame" : item.type === "function" ? "cloud" : "refresh"}
                noPadding
              />
            </div>
          </div>
          <div className="custom-line-hr" />
          <p>
            {t("label-user")}:{" "}
            <strong>
              {item.name || "-"} - {item.profile || "-"}
            </strong>
          </p>
          <div className="flex-row align-top">
            <p style={{ marginRight: 8 }}>{t("label-change")}:</p>
            <p className="break-word-container" style={{ color: "#5a5a5a", width: "85%" }}>{item.dataString || "-"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardChangelog;

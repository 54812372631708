import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import CardListContacts from "./cardlist-contact/cardlist-contacts";
import { SparkDialog } from "@bosch-web-dds/spark-ui-react";
import { useEffect, useState } from "react";
import ContactForm from "./contact-form/contact-form";
import { getContactsById } from "./contacts-service";
import { ContactInfoDTO } from "./contacts-DTO";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../contexts/auth-context";

const Contacts: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = { originId: id };

  const [contacts, setContacts] = useState<ContactInfoDTO[]>([]);

  const fetchContacts = async () => {
    if (id) {
      try {
        const data = await getContactsById(id);
        setContacts(data);
      } catch (error) {
        console.error("Error fetching scrap categories: ", error);
      }
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [id, setContacts]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchContacts();
  };
  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <ContactForm contactId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            <ImportExportCsv
              exportApi="exportcontactscsv"
              importApi="importcontactscsv"
              data={data}
              onClose={handleCloseModal}
            />
          </div>

          <CardListContacts onClose={handleCloseModal} contacts={contacts} />
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default Contacts;

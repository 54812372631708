import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOutFirebase } from "../../services/auth";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutAndRedirect = async () => {
      await signOutFirebase();
      navigate("/");
    };

    logoutAndRedirect();
  }, [navigate]);

  return null;
}

import React from "react";
import industryIcon from "../../../../../assets/svgs/industry.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScrapGeneratorInfoDTO } from "../../../scrap-generators-DTO";

interface Props {
  item: ScrapGeneratorInfoDTO;
}

const CardScrapGenerator: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="flex-row card-dealer"
      onClick={() => {
        navigate(`/scrap-generators-details/${item.id}/register`);
      }}
    >
      <div className="flex-row left">
        <img src={industryIcon} alt="industry-icon" />
        <div className="flex-column infos">
          <h3>{item.legalName || "-"}</h3>
          <p>
            {t("label-region")}: <strong>{item.region || "-"}</strong>
          </p>
          <p>
            {t("label-next-contract-expiration")}:{" "}
            <strong>{item.nextContractDueDate || "-"}</strong>
          </p>
        </div>
      </div>
      <div className="flex-column right">
        <p className="type">{t("label-scraps")}</p>
        {/* <p className='price'>XX,XX</p> */}
      </div>
    </div>
  );
};

export default CardScrapGenerator;

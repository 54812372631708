import { useTranslation } from "react-i18next";
import CardListScrapGenerator from "../../../cardlist-scrap-generator/cardlist-scrap-generator";
import { useNavigate, useParams } from "react-router-dom";
import addIcon from "../../../../../../assets/svgs/add.svg";
import { getBranchsScrapGeneratorsById } from "../../../../scrap-generator-service";
import { useEffect, useState } from "react";
import { ScrapGeneratorInfoDTO } from "../../../../scrap-generators-DTO";
import ImportExportCsv from "../../../../../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../../../../../contexts/auth-context";

const ScrapGeneratorDetailsBranchs: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const headquarterId = { headquarterId: id };
  const [scrapGeneratorBranchs, setScrapGeneratorBranchs] = useState<
    ScrapGeneratorInfoDTO[]
  >([]);

  const fetchScrapGenerators = async () => {
    try {
      let data: any;

      data = await getBranchsScrapGeneratorsById(id as string);

      setScrapGeneratorBranchs(data);
    } catch (error) {
      console.error("Error fetching scrap Generators: ", error);
    }
  };

  useEffect(() => {
    fetchScrapGenerators();
  }, []);

  const handleCloseModal = () => {
    fetchScrapGenerators();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => {
              navigate(`/scrap-generators-new/${id}`);
            }}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>
          <ImportExportCsv
            exportApi="exportscrapgeneratorscsv"
            importApi="importscrapgeneratorscsv"
            data={headquarterId}
            onClose={handleCloseModal}
          />
        </div>
        <br />
        <CardListScrapGenerator scrapGenerators={scrapGeneratorBranchs} />
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapGeneratorDetailsBranchs;

import React from "react";
import "../../styles/landing-page.css";
import { useTranslation, Trans } from "react-i18next";
import CardInfosPrice from "./components/card-infos-price/card-infos-price";
import CardLandipageDetals from "./components/card-landipage-detals/card-landipage-detals";
import ClickRepresentative from "./components/click-representative/click-representative";
import ClickRepresentativeMobile from "./components/click-representative-mobile/click-representative-mobile";
import CardTexts from "./components/card-texts/card-texts";
import CardFeedback from "./components/card-feedback/card-feedback";
import IconEmail from "./components/icon-email/icon-email";
import CardDetals from "./components/card-detals/card-detals";
import CardDetalsMobile from "./components/card-detals-mobile/card-detals-mobile";
import mapa from "../../assets/img/mapa.png";
import Footer from "./components/footer/footer";
import icon from "../../assets/img/icon.svg";
import { Analytics, logScreenViewAnalytics } from "../../services/analytics";

const LandingPage: React.FC = () => {
  const { t } = useTranslation();

  const subject = t("mail.subject");
  const body = t("mail.body");

  logScreenViewAnalytics(Analytics.LANDING_PAGE)

  return (
    <div className="landing-page-section page">
      <div className="title-description">
        <h2 className="title-initial">
          <Trans
            i18nKey={t("landing-page.title")}
            components={{ bold: <strong /> }}
          />
        </h2>
        <h2 className="title-main">{t("landing-page.title-main")}</h2>
        <p className="description">{t("landing-page.legend")}</p>
      </div>

      <CardLandipageDetals />
      <div className="container-representative-tablet font-weight">
        <div>
          <a className="title-click" href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
            {t("landing-page.representative")}
          </a>
          <a className="title-click" href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
            {t("landing-page.representatives")}
          </a>
        </div>
        <div className="icon">
          <img
            width={25}
            height={25}
            src={icon}
            alt="ilustration - Bosch Persons construct layout"
          />
        </div>
      </div>

      <CardTexts
        title={t("landing-page.benefits")}
        description="landing-page.description-benefits"
      />
      <CardInfosPrice />
      <CardDetals />
      <CardDetalsMobile />
      <CardFeedback />
      <div className="title-description">
        <h3>{t("landing-page.title-location")}</h3>
      </div>

      <div>
        <div className="control">
          <img src={mapa} alt="maps" />
          <div className="superior">
            <span className="text-country">
              {t("landing-page.title-country")}
            </span>
            <div className="text-countrys">
              <span>{t("countrys.countrys-one")}</span>
              <span>{t("countrys.countrys-two")}</span>
              <span>{t("countrys.countrys-three")}</span>
              <span>{t("countrys.countrys-four")}</span>
              <span>{t("countrys.countrys-five")}</span>
              <span>{t("countrys.countrys-six")}</span>
              <span>{t("countrys.countrys-seven")}</span>
              <span>{t("countrys.countrys-eight")}</span>
              <span>{t("countrys.countrys-nine")}</span>
              <span>{t("countrys.countrys-ten")}</span>
              <span>{t("countrys.countrys-eleven")}</span>
              <span>{t("countrys.countrys-twelve")}</span>
              <span>{t("countrys.countrys-thirteen")}</span>
            </div>
          </div>
        </div>

        <div className="superior-country">
          <span className="text-country">
            {t("landing-page.title-country")}
          </span>
          <div className="text-countrys">
            <span>{t("countrys.countrys-one")}</span>
            <span>{t("countrys.countrys-two")}</span>
            <span>{t("countrys.countrys-three")}</span>
            <span>{t("countrys.countrys-four")}</span>
            <span>{t("countrys.countrys-five")}</span>
            <span>{t("countrys.countrys-six")}</span>
            <span>{t("countrys.countrys-seven")}</span>
            <span>{t("countrys.countrys-eight")}</span>
            <span>{t("countrys.countrys-nine")}</span>
            <span>{t("countrys.countrys-ten")}</span>
            <span>{t("countrys.countrys-eleven")}</span>
            <span>{t("countrys.countrys-twelve")}</span>
            <span>{t("countrys.countrys-thirteen")}</span>
          </div>
        </div>
      </div>

      <CardTexts
        title={t("landing-page.titles")}
        description="landing-page.text-titles"
      />
      <ClickRepresentative />
      <ClickRepresentativeMobile />
      <IconEmail />

      <Footer />
    </div>
  );
};

export default LandingPage;

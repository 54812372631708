import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, firestore } from "../utils/firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../assets/enums/firebase-colections"; // collections ERRO GRAMATICAL

export function VerifyAccessFunction() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        const userRef = doc(firestore, COLLECTIONS.USERS, user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          await updateDoc(userRef, {
            lastAccess: new Date().toLocaleString(),
          });
          const userProfile = userDoc.data();
          const profile = userProfile.profile
          localStorage.setItem('scrap4better-session-profile', profile);
        }
      } else {
        localStorage.setItem('scrap4better-session-profile', "public");
        if (location.pathname !== "/" && !location.pathname.includes("login") && !location.pathname.includes("privacy-notice")) {
          navigate("/");
        }
      }
    },);

    return () => unsubscribe();
  }, [location.pathname]);

  return null;
}


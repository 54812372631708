import {
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";

import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { ObservationsDTO, ScrapsCategoryInfoDTO, ScrapsCategoryDTO } from "./scraps-DTO";
import { saveNewAsync, saveNewWithId, update, callFunction } from "../../services/persistence";

// add new 
export async function addNewScrapCategory(data: ScrapsCategoryDTO) {
  if (data.id == undefined) {

    saveNewAsync(COLLECTIONS.SCRAP_CATEGORIES, data.scrapsCategoryInfo).then(
      (docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);  
    });
  } else {
    update(COLLECTIONS.SCRAP_CATEGORIES, data.scrapsCategoryInfo, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    callFunction("updatescrapcategoryname", {scrapCategoryId: data.id, scrapCategoryName: data.scrapsCategoryInfo.name}, data.id);
  }
}

// get all scrapCategories
export async function getScrapCategories(): Promise<ScrapsCategoryInfoDTO[]> {
  const querySnapshot = await getDocs(
    collection(firestore, COLLECTIONS.SCRAP_CATEGORIES)
  );
  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as ScrapsCategoryInfoDTO[];
  return items;
}

export const getScrapCategoryById = async (
  id: string
): Promise<ScrapsCategoryDTO | null> => {
  const scrapsCategoryInfo = await getScrapCategory(id);
  const observations = await getScrapCategoryObservation(id);

  return {
    id,
    scrapsCategoryInfo,
    observations,
  };
};

export async function getScrapCategory(
  id: string
): Promise<ScrapsCategoryInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_CATEGORIES, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapsCategoryInfoDTO;
}

export async function getScrapCategoryObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
